import { makeStyles } from "@material-ui/styles";

export const useHomePageStyles = makeStyles({
  root: {},
  locTable: {
    borderSpacing: "0px 8px",
  },
  tableTitle: {
    padding: "8px 15px",
    backgroundColor: "#8A9199",
    opacity: 1,
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    "&:first-of-type": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "&:last-of-type": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  tableBody: {
    backgroundColor: "#FFF",
  },
  tableCell: {
    padding: "8px 15px",
    fontSize: "16px",
    "&:first-of-type": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "&:last-of-type": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  locIdLink: {
    cursor: "pointer",
    color: "blue",
    textDecoration: "underline",
    display: "inline-block",
  },
  contentRow: {
    height: "55px",
  },
  loadingRow: {
    textAlign: "left",
    backgroundColor: "#F2F3F4",
    borderBottom: "none"
  },
  daysDiff: {
    color: "#91979E",
  },
});
