import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Grid, Typography } from "@material-ui/core";

import check from "assets/check.png";
import { useWorkflowPanelStyles } from "./styles";
 
export default function WorkflowPanel({
  handleViewLoc,
  handleViewDoc,
  onUploadDocument,
  requiredDocs,
  handlePreparePresentation,
  handleApprovePresentation,
  handleConfirmPayment,
  approvalStatus,
}) {
  const classes = useWorkflowPanelStyles();

  const OPEN = "open";
  const BILL_OF_LADING = "billOfLading";
  const CERT_OF_ANALYSIS = "certificateOfAnalysis";
  const COMMERCIAL_INVOICE = "commercialInvoice";
  const CERT_OF_WEIGHT = "certificateOfWeight";
  const CERT_OF_ORIGIN = "certificateOfOrigin";
  const WAITING_TO_RECEIVE = "waitingToReceive";
  const NEEDS_APPROVAL = "needsApproval";
  const REJECTED = "rejected";
  const PRESENTATION_REJECTED = "presentationRejected";
  const PENDING_PRESENTATION_REVIEW = "pendingPresentationReview";
  const APPROVE_PRESENTATION = "presentationApproved";
  const CONTURA_OPERATIONS = "ConturaOperations";

  const { userGroup } = useSelector((state) => state.authReducer);
  const { loc } = useSelector((state) => state.locDetailsReducer);
  const { presentationDocuments } = loc;
  
  const chooseStatusMessage = (status, docType, defaultMsg ) => {
    switch (status) {
    case WAITING_TO_RECEIVE:
        return (
          <span
            className={classes.uploadDocument}
            onClick={() => onUploadDocument(docType)}
          >
            Upload signed <b>{defaultMsg}</b>
          </span>
        )
    case REJECTED:
      return (
        <span
          className={classes.uploadDocument}
          onClick={() => onUploadDocument(docType)}
        >
          Upload signed <b>{defaultMsg}</b>
        </span>
      )
    case NEEDS_APPROVAL:
      return (
        <>
          <span className={classes.completedTask}>
            <img src={check} className={classes.check} alt={"check"}/>
            Upload signed <b>{defaultMsg}</b>
          </span>
          <span className={classes.completedTask}> | </span>
          <span
            className={classes.viewDoc}
            onClick={() => handleViewDoc(docType)}
          >
            {userGroup === CONTURA_OPERATIONS ? "Approve" : "View"}
          </span>
        </>
      );
    default:
      return (
        <>
          <span className={classes.completedTask}>
            <img src={check} className={classes.check} alt={"check"}/>
            Upload signed <b>{defaultMsg}</b>
          </span>
          <span className={classes.completedTask}> | </span>
          <span
            className={classes.viewDoc}
            onClick={() => handleViewDoc(docType)}
          >
              View
          </span>
        </>
      );
    }
  };

  const checkPreparePresentationReady = () => {
    var result = true
    presentationDocuments && Object.keys(presentationDocuments).length
    ? Object.keys(presentationDocuments).map(key => {
      return presentationDocuments[key].status !== "approved"
       ? result = false
       : null
    })
    : result = true
    return result
  }

  return (
    requiredDocs 
      ? (
        <div className={classes.root}>
          <Typography variant={"h6"}>Workflow</Typography>
          <div className={classes.approvalStepsGroup}>
            <div className={classes.documentBox}>
              <div>
                <span className={classes.completedTask}>
                  <img src={check} className={classes.check} alt={"check"}/>
                  Upload <b>Letter of Credit</b> | </span>
                <span className={classes.viewDoc} onClick={handleViewLoc}>View</span>
              </div>
              <div className={classes.ovalInactive}><span>R</span></div>
            </div>
          </div>
          <div className={classes.approvalStepsGroup}>
            {requiredDocs.certificateOfAnalysis && presentationDocuments.certificateOfAnalysis && (
              <div className={classes.documentBox}>
                <div>
                  {
                    chooseStatusMessage(presentationDocuments.certificateOfAnalysis.status, CERT_OF_ANALYSIS, "Certificate of Analysis")
                  }
                </div>
                <div
                  className={
                    presentationDocuments.certificateOfAnalysis.status === WAITING_TO_RECEIVE
                      ? classes.ovalContura
                      : classes.uploadedOval
                  }
                >
                  <span>C</span>
                </div>
              </div>
            )}
            {requiredDocs.billOfLading && presentationDocuments.billOfLading && (
              <div className={classes.documentBox}>
                <div>
                  {
                    chooseStatusMessage(presentationDocuments.billOfLading.status, BILL_OF_LADING, "Bill of Lading")
                  }
                </div>
                <div
                  className={
                    presentationDocuments.billOfLading.status === WAITING_TO_RECEIVE
                      ? classes.ovalContura
                      : classes.uploadedOval
                  }
                >
                  <span>C</span></div>
              </div>
            )}
            {requiredDocs.commercialInvoice && presentationDocuments.commercialInvoice && (
              <div className={classes.documentBox}>
                <div>
                  {
                    chooseStatusMessage(presentationDocuments.commercialInvoice.status, COMMERCIAL_INVOICE, "Commercial Invoice")
                  }
                </div>
                <div
                  className={
                    presentationDocuments.commercialInvoice.status === WAITING_TO_RECEIVE
                      ? classes.ovalContura
                      : classes.uploadedOval
                  }
                >
                  <span>C</span>
                </div>
              </div>
            )}
            {requiredDocs.certificateOfWeight && presentationDocuments.certificateOfWeight && (
              <div className={classes.documentBox}>
                <div>
                  {
                    chooseStatusMessage(presentationDocuments.certificateOfWeight.status, CERT_OF_WEIGHT, "Certificate of Weight")
                  }
                </div>
                <div
                  className={
                    presentationDocuments.certificateOfWeight.status === WAITING_TO_RECEIVE
                      ? classes.ovalContura
                      : classes.uploadedOval
                  }
                >
                  <span>C</span>
                </div>
              </div>
            )}
            {requiredDocs.certificateOfOrigin && presentationDocuments.certificateOfOrigin && (
              <div className={classes.documentBox}>
                <div>
                  {
                    chooseStatusMessage(presentationDocuments.certificateOfOrigin.status, CERT_OF_ORIGIN, "Certificate of Origin")
                  }
                </div>
                <div
                  className={
                    presentationDocuments.certificateOfOrigin.status === WAITING_TO_RECEIVE
                      ? classes.ovalContura
                      : classes.uploadedOval
                  }
                >
                  <span>C</span>
                </div>
              </div>
            )}
          </div>

          <div className={classes.approvalStepsGroup}>
            <div className={classes.documentBox}>
              <div 
                onClick={
                  (loc.status === OPEN || loc.status === PRESENTATION_REJECTED) && checkPreparePresentationReady() 
                   ? handlePreparePresentation
                   : null} 
                className={
                  approvalStatus.preparePresentation && loc.status !== PRESENTATION_REJECTED 
                  ? classes.disabled 
                  : checkPreparePresentationReady()
                    ? classes.uploadDocument 
                    : classes.inactiveStep}>
                {(approvalStatus.preparePresentation && loc.status !== PRESENTATION_REJECTED) && (
                  <img src={check} className={classes.check} alt={"check"}/>
                )}
                <span>
                  {"Prepare & send "}<b>presentation</b>
                </span>
              </div>
              <div 
                className={
                  approvalStatus.preparePresentation && loc.status !== PRESENTATION_REJECTED
                  ? classes.uploadedOval
                  : classes.ovalContura
                }
              >
                <span>C</span>
              </div>
            </div>
            <div className={classes.documentBox}>
              <div 
                onClick={
                  (!approvalStatus.approvePresentation) && loc.status === PENDING_PRESENTATION_REVIEW
                  ? handleApprovePresentation
                  : null} 
                className={
                  approvalStatus.approvePresentation && loc.status !== PRESENTATION_REJECTED
                  ? classes.disabled
                  : approvalStatus.preparePresentation && loc.status === PENDING_PRESENTATION_REVIEW
                    ?  classes.uploadDocument 
                    : classes.inactiveStep}>
                {(approvalStatus.approvePresentation && loc.status !== PRESENTATION_REJECTED) && (
                  <img src={check} className={classes.check} alt={"check"}/>
                )}
                <span>
                  Review <b>presentation</b>
                </span>
              </div>
              <div
                className={
                  approvalStatus.approvePresentation && loc.status !== PRESENTATION_REJECTED
                  ? classes.uploadedOval
                  : classes.ovalRegions
                }
              >
                <span>R</span>
              </div>
            </div>
          </div>
          
          <div className={classes.completionSteps}>
            <div className={classes.documentBox}>
              <div 
                onClick={
                  !approvalStatus.closePresentation && loc.status === APPROVE_PRESENTATION
                  ? handleConfirmPayment
                  : null
                } 
                className={
                  approvalStatus.closePresentation 
                  ? classes.disabled 
                  : approvalStatus.approvePresentation && loc.status === APPROVE_PRESENTATION
                    ? classes.uploadDocument 
                    : classes.inactiveStep}>
                {approvalStatus.closePresentation && (
                  <img src={check} className={classes.check} alt={"check"}/>
                )}
                <span>
                  Confirm <b>payment</b> has been received
                </span>
              </div>
              <div 
                className={
                  approvalStatus.closePresentation
                  ? classes.uploadedOval
                  : classes.ovalContura
                }
              >
                <span>C</span>
              </div>
            </div>
          </div>
          <div className={classes.participants}>
            <Typography>Participants</Typography>
            <Grid container className={classes.participant}>
              <Grid item className={classes.ovalContura}><span>C</span></Grid>
              <Grid item className={classes.participantLabel}>
                Contura Coal Sales, LLC
                <p>Seller</p>
              </Grid>
            </Grid>

            <Grid container className={classes.participant}>
              <Grid item className={classes.ovalRegions}><span>R</span></Grid>
              <Grid item className={classes.participantLabel}>
                Regions Bank
                <p>Seller&apos;s Bank</p>
              </Grid>
            </Grid>
          </div>
        </div> )
      : null
  );
}

WorkflowPanel.propTypes = {
  handleViewLoc: PropTypes.func,
  handleViewDoc: PropTypes.func,
  requiredDocs: PropTypes.object,
  onUploadDocument: PropTypes.func,
  handlePreparePresentation: PropTypes.func,
  handleApprovePresentation: PropTypes.func,
  handleConfirmPayment: PropTypes.func,
  approvalStatus: PropTypes.object,
};