import React from "react";
import PropTypes from "prop-types";
import userIcon from "assets/user.png";
import SimplePopover from "components/popover";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useHeaderStyles } from "./styles";

export default function Header({ history }) {
  const classes = useHeaderStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const contura = "https://contura-web-assets.s3.amazonaws.com/images/contura.png";
  const logo = "https://contura-web-assets.s3.amazonaws.com/images/logo.png";

  let { identityToken } = useSelector((state) => state.authReducer);
  let { userGroup } = useSelector((state) => state.authReducer);
  let { userName } = useSelector((state) => state.authReducer);
  let { email } = useSelector((state) => state.authReducer);

  const handleNavChange = () => {
    history.push({
      pathname: "/loc",
    });
  };

  const handleAccountIconClick = event => {
    setAnchorEl(event.currentTarget);
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    history.push({
      pathname: "/logout",
    });
  }

  const popoverContent = (
    <div className={classes.popoverContent}>
      <div className={classes.popoverRow}>
        <p className={classes.popoverText}><strong>{userName}</strong></p>
        <p className={classes.popoverText}><strong>{email}</strong></p>
        <p className={classes.grayText}><i>{userGroup}</i></p>
      </div>
      <hr className={classes.hr} />
      <div className={classes.popoverRow} onClick={handleLogout}>
        <p className={clsx(classes.popoverText,classes.link)}>Sign Out</p>
      </div>  
    </div>
  )
  return(
    <div className={classes.root}>
      <div className={classes.logoArea}>
        <div onClick={handleNavChange} className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt={"logo"} />
          <img src={contura} className={classes.contura} alt={"contura"} />
        </div>
        <span className={classes.title}>Letter of Credit Portal</span>
      </div>
      {identityToken && (
        <div className={classes.accountIconContainer}>
          <img 
            src={userIcon} 
            className={classes.userIcon} 
            onClick={handleAccountIconClick} 
            alt={"userIcon"} />
          <SimplePopover
            handleClose={handlePopoverClose}
            anchorEl={anchorEl}
            content={popoverContent}
          />
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  history: PropTypes.object,
};