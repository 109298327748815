/* eslint-disable quotes */
import { makeStyles, withStyles } from "@material-ui/styles";
import { InputBase } from "@material-ui/core";

export const useDetailsStyles = makeStyles(theme => ({
  root: {},
  leftContainer: {
    width: "70%",
    height: `calc(100vh - ${theme.headerHeight} - .5rem)`
  },
  rightContainer: {
    width: "30%",
    height: `calc(100vh - ${theme.headerHeight} - .5rem)`,
    overflowY: "hidden",
  },
  numberInput: {
    "& input": {
      width: 45,
      paddingRight: 10,
    },
  },
  pdfIcon: {
    width: 60,
    height: 75
  },
  textInDropZone: {
    margin: 0,
    marginTop: 5, 
    size: 11,
    fontWeight: 400,
    color: "#8C939B",
    "&:hover": {
      cursor: "default",
    },
  },
  greenText: {
    color: "#52B1B5",
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
    },
  },
  grayText: {
    marginTop: 20,
    color: "#232F3E",
    opacity: 0.5
  },
  inlineGrayText: {
    color: "#232F3E",
    opacity: 0.5,
    margin: "0px 3px"
  },
  paymentInput: {
    height: 25,
    borderRadius: 3,
    border: "1px solid lightgray"
  },
  paymentInputContainer: {
    marginTop: 20
  },
  modalContent: {
    display: "inline-block !important",
    textAlign: "center !important",
    color: "#A7A1B3",
    "&:hover": {
      cursor: "default",
    },
  },
}));

export const BootstrapInput = withStyles(() => ({
  root: {
    "label + &": {
      marginTop: 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFF",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
