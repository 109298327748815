import {
    PRESENTATION_PREPARED,
    PRESENTATION_APPROVED,
    PRESENTATION_REJECTED,
    PRESENTATION_CLOSED
    } from "store/constants";
    
    const initialState = {
      isFetching: false,
      transactionId: null,
    };
    
    // eslint-disable-next-line no-unused-vars
    
    export const locPresentationReducer = (state = initialState, action) => {
      const { payload, type } = action;
      switch(type) {
      case PRESENTATION_PREPARED:
        return {
          ...state,
          isFetching: false,
          transactionId: payload.transactionId
        };
      case PRESENTATION_APPROVED:
        return {
          ...state,
          isFetching: false,
          transactionId: payload.transactionId
        };
      case PRESENTATION_REJECTED:
        return {
          ...state,
          isFetching: false,
          transactionId: payload.transactionId
        };
      case PRESENTATION_CLOSED:
        return {
          ...state,
          isFetching: false,
          transactionId: payload.transactionId
      };
      default:
        return state;
      }
    };
    