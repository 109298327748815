import {
  PRESENTATION_APPROVED,
  PRESENTATION_CLOSED,
  PRESENTATION_PREPARED,
  PRESENTATION_REJECTED,
  SHOW_SNACKBAR,
} from "store/constants";

import { loc as locAPI } from "api";
import { apiErrorHandler } from "store/actions";
import { setSnackBarDisplay } from "store/actions/displayActions";

export const ACTION_PRESENTATION_PREPARED = (transactionId) => ({
  type: PRESENTATION_PREPARED,
  payload: { transactionId }
});

export const ACTION_PRESENTATION_APPROVED = (transactionId) => ({
  type: PRESENTATION_APPROVED,
  payload: { transactionId }
});

export const ACTION_PRESENTATION_REJECTED = (transactionId) => ({
  type: PRESENTATION_REJECTED,
  payload: { transactionId }
});

export const ACTION_PRESENTATION_CLOSED = (transactionId) => ({
  type: PRESENTATION_CLOSED,
  payload: { transactionId }
});

export const preparePresentation = (locID) => {
  return async(dispatch) => {
    return await locAPI.preparePresentation(locID)
      .then((response) => {
        const { data } = response;
        const transactionId = data.transactionId;  
        dispatch(ACTION_PRESENTATION_PREPARED(transactionId));
        dispatch(
          setSnackBarDisplay(
            SHOW_SNACKBAR, 
            {
              status: "success",
              message: "Prepared and sent presentation"
            }
          )
        );
      }).catch(apiErrorHandler);
  };
};

export const approvePresentation = (locID) => {
  return async(dispatch) => {
    return await locAPI.approvePresentation(locID)
      .then((response) => {
        const { data } = response;
        const transactionId = data.transactionId;
        dispatch(ACTION_PRESENTATION_APPROVED(transactionId));
        dispatch(
          setSnackBarDisplay(
            SHOW_SNACKBAR, 
            {
              status: "success",
              message: "Presentation approved"
            }
          )
        );
      }).catch(apiErrorHandler);
  };
};

export const rejectPresentation = (locID) => {
  return async(dispatch) => {
    return await locAPI.rejectPresentation(locID)
      .then((response) => {
        const { data } = response;
        const transactionId = data.transactionId;
        dispatch(ACTION_PRESENTATION_REJECTED(transactionId));
        dispatch(
          setSnackBarDisplay(
            SHOW_SNACKBAR, 
            {
              status: "success",
              message: "Presentation rejected"
            }
          )
        );
      }).catch(apiErrorHandler);
  };
};

export const closePresentation = (locID, paymentAmount) => {
  return async(dispatch) => {
    return await locAPI.closePresentation(locID, paymentAmount)
      .then((response) => {
        const { data } = response;
        const transactionId = data.transactionId;  
	      dispatch(ACTION_PRESENTATION_CLOSED(transactionId));
        dispatch(
          setSnackBarDisplay(
            SHOW_SNACKBAR, 
            {
              status: "success",
              message: "Payment approved",
            }
          )
        );
      }).catch(apiErrorHandler);
  };
};