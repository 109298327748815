import { config } from "config";
import { sendRequest } from "./http";

export const getLocDetails = async (locId) => {
  const URL = config.baseURL + `locs/${locId}`;
  let headers = {"content-type": "application/json"};
  return await sendRequest("GET", URL, null, headers);
};

export const updateLoc = async (locId, loc) => {
  const URL = config.baseURL + `locs/${locId}`;
  let headers = {"content-type": "application/json"};
  return await sendRequest("PUT", URL, loc, headers);
};

export const getLocs = async() => {
  const URL = config.baseURL + "locs";
  return await sendRequest("GET", URL);
};

export const createLOC = async(file, documentaryCreditNumber) => {
  const URL = config.baseURL + "locs";
  const formData = new FormData();
  formData.append("document", file);
  formData.append("documentaryCreditNumber", documentaryCreditNumber);

  let headers = {"content-type": "multipart/form-data"};
  return await sendRequest("POST", URL, formData, headers);
};

export const uploadAmendment = async(locID, file) => {
    const URL = config.baseURL + "locs/" + locID + "/documents/SWIFT707";
    const headers = {"content-type": "application/pdf"};
    return await sendRequest("POST", URL, file, headers);
};

export const preparePresentation = async(locID) => {
    const URL = config.baseURL + "locs/" + locID + "/prepare";
    return await sendRequest("POST", URL);
};

export const approvePresentation = async(locID) => {
    const URL = config.baseURL + "locs/" + locID + "/approve";
    return await sendRequest("POST", URL);
};

export const rejectPresentation = async(locID) => {
    const URL = config.baseURL + "locs/" + locID + "/reject";
    return await sendRequest("POST", URL);
};

export const closePresentation = async(locID, paymentAmount = 0) => {
    const URL = config.baseURL + "locs/" + locID + "/close";
    let headers = {"content-type": "application/json"};
    let body = {"paymentAmount": paymentAmount};
    return await sendRequest("POST", URL, body, headers);
};

export const getEventLogs = async(locID) => {
  const URL = config.baseURL + "locs/" + locID +"/events";
  return await sendRequest("GET", URL);
};