import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import StyledTableCell from "./StyledTableCell";
import StyledTableRow from "./StyledTableRow";

import { tableStyle } from "./style";

export default function SimpleTable ({
  columns,
  data
}) {
  const classes = tableStyle();
  return (
    <>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => (
              <StyledTableCell key={idx}>{column.label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(data).map((dt, idx1) => (
            <StyledTableRow key={idx1}>
              {
                columns.map((column, idx2) => (
                  <StyledTableCell align="left" key={idx2}>{dt[column.name]}</StyledTableCell>
                ))
              }
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};
