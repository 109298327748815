import { createStyles, makeStyles } from "@material-ui/styles";

export const ModalStyle = makeStyles(() =>
  createStyles({
    paper: {
      "position": "absolute",
      "backgroundColor": "#FFF",
      "boxShadow": 5,
      "padding": 0,
      "borderRadius": 5,
      "&:focus": {
        outlineWidth: 0
      }
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      height: 50,
      padding: "0 .75rem",
      color: "white",
      backgroundColor: "#232F3E",
      alignItems: "center",
      borderRadius: "5px 5px 0px 0px",
      "& p": {
        paddingLeft: ".5rem",
      },
      "& button": {
        padding: "6px",
      },
    },
    closeIcon: {
      color: "white",
      width: 15
    },
    modalBody: {
      margin: "0 auto",
      backgroundColor: "#F2F3F4"
    }
  })
);
