import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

import { OverlayWithLoaderStyle } from "./style";

export default function OverlayWithLoader({ overlayDisplay }) {
  const classes = OverlayWithLoaderStyle();
  return(
    <div
      className={classes.overlay}
      style={{display: overlayDisplay}}
    >
      <div className={classes.overlayText}>
        <Loader
          type={"Oval"}
          color={"#52B1B5"}
          height={50}
          width={50}
        />
      </div>
    </div>
  );
}

OverlayWithLoader.propTypes = {
  overlayDisplay: PropTypes.any,
};
