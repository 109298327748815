import { makeStyles } from "@material-ui/styles";

export const useDropZoneStyles = makeStyles({
  root: {
    width: "100%",
    height: 250,
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "#EAEBED",
    borderRadius: "5px",
    "&:active": {
      outlineColor: "#5AB4B8",
    },
    "&:hover": {
      outlineColor: "#5AB4B8",
    },
  },
  files: {
    display: "inline-block !important",
    textAlign: "center !important",
    color: "#A7A1B3",
  },
  pdfIcon: {
    width:30,
    height:35
  },
});
