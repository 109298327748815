import {
  ADD_NEW_LOC,
  NEW_LOC_CREATED,
  LOC_CREATE_ERROR,
} from "store/constants";

const initialState = {
  isFetching: false,
  transactionId: null,
  createError: null
};

// eslint-disable-next-line no-unused-vars

export const locCreateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch(type) {
  case ADD_NEW_LOC:
    return {
      ...state,
      createdAt: Date.now(),
      isFetching: true,
      transactionId: null
    };
  case NEW_LOC_CREATED:
    return {
      ...state,
      isFetching: false,
      transactionId: payload.transactionId
    };
  case LOC_CREATE_ERROR:
    return {
      ...state,
      isFetching: false,
      createError: payload.errorMessage
    };
  default:
    return state;
  }
};
