export * from "./authActions";
export * from "./displayActions";
export * from "./locActions";
export * from "./presentationActions";
export * from "./presentationDocActions";

// Generic API error handler
export const apiErrorHandler = (err) => {
  console.log("in api error handler with err ", err);
  /* err : {status, message} */
  if (err.status === 401) {
    //redirect to signin
    window.location.assign("/logout");
  }
};