import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Grid} from "@material-ui/core";

import { closePresentation } from "store/actions";

import NewApproveModal from "App/NewApproveModal"
import { ConfirmPaymentModalStyle } from "./styles";

export default function ConfirmPaymentModal({
  id,
  isOpen,
  handleClose,
}) {
  const [paymentAmount, setPaymentAmount] = React.useState("");
  const classes = ConfirmPaymentModalStyle();
  const dispatch = useDispatch();

  const handleApprove = async () => {
    if (paymentAmount.length > 0) {
      handleClose();
      await dispatch(closePresentation(id, paymentAmount));
    }
  };

  const handleReject = () => {
    handleClose();
  };

  const modalContent = (
    <Grid container>
      <Grid item xs={12}>
        <div> 
          PAYMENT AMOUNT<span className={classes.inlineGrayText}>(USD)</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.paymentInputContainer}> 
          <span className={classes.inlineGrayText}>$</span>
          <input 
            type="text" 
            name="paymentAmount"
            className={classes.paymentInput} 
            defaultValue={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}/>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.grayText} style={{fontSize:15}}> 
          Note: This will automatically close the LC.
        </div>
      </Grid>
    </Grid>
  );

  return (
    <NewApproveModal
      isOpen={isOpen}
      width={350}
      handleClose={handleClose}
      headerText={"Confirm payment"}
      approveButtonText={"Confirm"}
      rejectButtonText={"Cancel"}
      handleApprove={handleApprove}
      handleReject={handleReject}
      content={modalContent}
    />
  );
}

ConfirmPaymentModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

