const RolesWithRestrictedAccess = {
  WorkflowPanel: {
        "uploadDocument": ["RegionsOperations", "Customer"],
        "preparePresentation": ["RegionsOperations", "Customer"],
        "approvePresentation": ["Customer"],
        "confirmPayment": ["RegionsOperations", "Customer"],
  },
  DetailsPanel: {
        "save":  ["RegionsOperations", "Customer"]
  }
}

export default RolesWithRestrictedAccess