import {
  ADD_PRESENTATION_DOCUMENT,
  NEW_PRESENTATION_DOCUMENT_CREATED,
  PRESENTATION_DOCUMENT_CREATE_ERROR,
  PRESENTATION_DOCUMENT_APPROVED,
  PRESENTATION_DOCUMENT_APPROVE_ERROR,
  PRESENTATION_DOCUMENT_REJECTED,
  PRESENTATION_DOCUMENT_REJECT_ERROR,
  SHOW_SNACKBAR,
} from "store/constants";

import { presentation as presentationAPI } from "api";
import { apiErrorHandler } from "store/actions";
import { setSnackBarDisplay } from "store/actions/displayActions";

export const ACTION_ADD_PRESENTATION_DOCUMENT = () => ({
  type: ADD_PRESENTATION_DOCUMENT,
});

export const ACTION_PRESENTATION_DOCUMENT_CREATED = (transactionId) => ({
  type: NEW_PRESENTATION_DOCUMENT_CREATED,
  payload: { transactionId }
});

export const ACTION_PRESENTATION_DOCUMENT_CREATE_ERROR = (errorMessage) => ({
  type: PRESENTATION_DOCUMENT_CREATE_ERROR,
  payload: { errorMessage }
});

export const ACTION_PRESENTATION_DOCUMENT_APPROVED = (transactionId) => ({
  type: PRESENTATION_DOCUMENT_APPROVED,
  payload: { transactionId }
});

export const ACTION_PRESENTATION_DOCUMENT_APPROVE_ERROR = (response) => ({
  type: PRESENTATION_DOCUMENT_APPROVE_ERROR,
  payload: { response }
});

export const ACTION_PRESENTATION_DOCUMENT_REJECTED = (transactionId) => ({
  type: PRESENTATION_DOCUMENT_REJECTED,
  payload: { transactionId }
});

export const ACTION_PRESENTATION_DOCUMENT_REJECT_ERROR = (response) => ({
  type: PRESENTATION_DOCUMENT_REJECT_ERROR,
  payload: { response }
});

export const addPresentationDocument = (locID, documentType, file) => {
  return async(dispatch) => {
    return await presentationAPI.createPresentationDocument(locID, documentType, file)
      .then((response) => {
        const { data } = response;
        let createResult = JSON.parse(data.putResult.Payload);
        const transactionId = createResult.transactionId;
        if (createResult.errorMessage) {
          console.log("Error adding presentation document: ", createResult.errorMessage);
          dispatch(ACTION_PRESENTATION_DOCUMENT_CREATE_ERROR(createResult.errorMessage));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "error",
                message: createResult.errorMessage,
              }
            )
          );
        } else {
          dispatch(ACTION_PRESENTATION_DOCUMENT_CREATED(transactionId));
          var result = documentType.replace( /([A-Z])/g, " $1" );
          var formattedType = result.charAt(0).toUpperCase() + result.slice(1);
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "success",
                message: `${formattedType} uploaded`,
              }
            )
          );
        }
      }).catch(apiErrorHandler);
  };
};

export const approvePresentationDocument = (locID, documentType) => {
  return async(dispatch) => {
    return await presentationAPI.approvePresentationDocument(locID, documentType)
      .then((response) => {
        const { data } = response;
        const transactionId = data.transactionId;
        if (Number(response.status) !== 200) {
          console.log("Error approving presentation document.");
          dispatch(ACTION_PRESENTATION_DOCUMENT_APPROVE_ERROR(response));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "error",
                message: "Failed to approve Presentation Document"
              }
            )
          );
        } else {
          dispatch(ACTION_PRESENTATION_DOCUMENT_APPROVED(transactionId));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "success",
                message: "Presentation document approved"
              }
            )
          );
        }
      }).catch(apiErrorHandler);
  };
};

export const rejectPresentationDocument = (locID, documentType) => {
  return async(dispatch) => {
    return await presentationAPI.rejectPresentationDocument(locID, documentType)
      .then((response) => {
        const { data } = response;
	      const transactionId = data.transactionId;
        if (Number(response.status) !== 200) {
          console.log("Error rejecting presentation document.");
          dispatch(ACTION_PRESENTATION_DOCUMENT_REJECT_ERROR(response));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "error",
                message: "Failed to reject Presentation document"
              }
            )
          );
        } else {
          dispatch(ACTION_PRESENTATION_DOCUMENT_REJECTED(transactionId));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "success",
                message: "Presentation document rejected"
              }
            )
          );
        }
      }).catch(apiErrorHandler);
  };
};
