import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fromUnixTime, format } from "date-fns";
import Drawer from "@material-ui/core/Drawer";
import Loader from "react-loader-spinner";

import { loc as locAPI } from "api";

import closeIcon from "assets/close.png";
import SimpleTable from "components/table";
import { useEventsLogStyles } from "./style";

const EventsLog = ({
  open,
  handleCloseEventsLog,
}) => {
  const classes = useEventsLogStyles();
  const { loc } = useSelector((state) => state.locDetailsReducer);
  const [eventsLog, setEventsLog] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const ATTRIBUTES_UPDATED = "updatedLetterOfCreditAttributes";
  const LOC_CREATED = "createdLetterOfCredit";
  const LOC_MARKED_PAID = "markedLOCPaid";
  const LOC_APPROVED = "approvedLOC";
  const LOC_REJECTED = "rejectedLOC";
  const PRESENTATION_SUBMITTED = "submittedPresentation";
  const PRESENTATION_DOC_UPLOADED = "uploadedPresentationDocument";
  const PRESENTATION_DOC_APPROVED = "approvedPresentationDocument";
  const PRESENTATION_DOC_REJECTED = "rejectedPresentationDocument";
  const SWIFT_DOC_UPLOADED = "uploadedSwiftDocument";

  React.useEffect(() => {
    async function fetchEventLogs () {
      const response = await locAPI.getEventLogs(loc.locID);
      var modifiedEventsLog = response.data.body.map((log) => {
        const myDate = new Date(log.createdAt).toLocaleDateString();
        const dateTime = format(fromUnixTime(log.createdAt/1000),"h:mmaaaa");
        return {
          ...log,
          eventType: formatEventType(log.eventType),
          date: myDate,
          time: formatTime(dateTime),
        };
      });
      
      modifiedEventsLog.sort((a,b) => {
        return b.createdAt - a.createdAt;
      });
      
      setEventsLog(modifiedEventsLog);
      setLoading(false)
    }

    if(loc.locID) {
      fetchEventLogs();
    }
  },[loc]);

  const formatTime = (eventTime) => {
    const flattenedTime = eventTime.split("").join("");
    const charIdx = flattenedTime.indexOf("p") !== -1 ? flattenedTime.indexOf("p") : flattenedTime.indexOf("a");
    const output = flattenedTime.substring(0, charIdx) + " " + flattenedTime.substring(charIdx).replace(/\./g, "");
    return output;
  };

  const formatEventType = (event) => {
    switch (event) {
    case ATTRIBUTES_UPDATED:
      return "Updated Letter of Credit attributes";
    case LOC_CREATED:
      return "Created Letter of Credit";
    case LOC_MARKED_PAID:
      return "Marked Letter of Credit Paid";
    case LOC_APPROVED:
      return "Approved Letter of Credit";
    case LOC_REJECTED:
      return "Rejected Letter of Credit";
    case PRESENTATION_SUBMITTED:
      return "Submitted presentation";
    case PRESENTATION_DOC_UPLOADED:
      return "Uploaded presentation document";
    case PRESENTATION_DOC_APPROVED:
      return "Approved presentation document";
    case PRESENTATION_DOC_REJECTED:
      return "Rejected presentation document";
    case SWIFT_DOC_UPLOADED:
      return "Uploaded Swift document";
    default:
      return "";
    }
  };

  const columns = [
    {label: "DATE", name: "date"},
    {label: "TIME", name: "time"},
    {label: "ACTIVITY", name: "eventType"},
    {label: "USER", name: "createdByUser"},
    {label: "BLOCKCHAIN TRANSACTION ID", name: "transactionId"},
  ];

  return (
    <>
      <Drawer anchor="bottom" open={open}>
        <div className={classes.pageContainer}>
          <div className={classes.titleContainer}>
            <div><strong>Activity Log</strong> for Letter of Credit #{loc.locID}</div>
            <div className={classes.closeButton} onClick={handleCloseEventsLog}>
              <img className={classes.closeIcon} src={closeIcon} alt={"closeIcon"} />
            </div>
          </div>
          {!loading
            ?
            <SimpleTable 
              columns={columns}
              data={eventsLog}
            />
            :
            <div>
              <Loader
                className={classes.loader}
                type={"Oval"}
                color={"#52B1B5"}
                height={50}
                width={50}
              />
            </div>
          }
        </div>
      </Drawer>
    </>
  );
};
export default EventsLog;

EventsLog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseEventsLog: PropTypes.func
};