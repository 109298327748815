/* eslint-disable quotes */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import Header from "App/Header";
import LettersOfCredit from "App/LettersOfCredit";
import LocDetails from "App/LocDetails";
import Signin from "App/Signin";
import Logout from "App/Logout";
import GlobalSnackBar from "App/SnackBar";
import GlobalOverlayWithLoader from "App/OverlayWithLoader";

import { browserHistory } from "store";
import { refreshUserFromSession } from "store/actions";
import { userIsLoggedIn } from "auth";
import { useAppContainerStyles } from "./styles";
import AWS from "aws-sdk";
import "./app.css";

AWS.config.region = "us-east-1";

export default function AppContainer() {
  const classes = useAppContainerStyles();

  let { identityToken } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  // If the user is arriving to the application not via the signin page, e.g.
  // directly typing the url, we need to check if they are already logged in,
  // and if so, populate the reducer with the user info.  But don't do this
  // if they are trying to logout
  if (!identityToken && userIsLoggedIn() && window.location.pathname !== "/logout") {
    dispatch(refreshUserFromSession());
  }

  return (
    <ConnectedRouter history={browserHistory}>
      <Route path={`/(!?loc|loc/:id)`} component={Header} />
      <Route path={`/(!?loc|loc/:id|signin)`} component={GlobalSnackBar} />
      <Route path={`/(!?loc|loc/:id)`} component={GlobalOverlayWithLoader} />
      <div className={classes.root}>
        <Switch>
          <Route
            exact
            path={"/"}
            component={() => <Redirect to={"/signin"} />}
          />
          <Route exact path={"/signin"} component={Signin} />
          <Route exact path={"/logout"} component={Logout} />
          {(userIsLoggedIn() || identityToken) &&
            <Route exact path={"/loc"} component={LettersOfCredit} />
          }
          {(userIsLoggedIn() || identityToken) &&
            <Route exact path={"/loc/:id"} component={LocDetails} />
          }
          <Redirect to={"/signin"} />
        </Switch>
      </div>
    </ConnectedRouter>
  );
}