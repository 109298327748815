import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { logout } from "auth";
import { logoutUser } from "store/actions";

export default function Logout() {

  let { identityToken } = useSelector((state) => state.authReducer);

  // Clear user from Cognito library session
  logout();

  const dispatch = useDispatch();

  if (identityToken) {
    // Clear user from reducer
    dispatch(logoutUser());
  }

  return (
    !identityToken && <Redirect to={"/signin"} />
  )
};