import { makeStyles } from "@material-ui/styles";

export const ConfirmPaymentModalStyle = makeStyles({
  contentContainer: {
    padding: 30,
    backgroundColor: "#EAEBED"
  },
  inlineGrayText: {
    color: "#232F3E",
    opacity: 0.5,
    margin: "0px 3px"
  },
  paymentInputContainer: {
    marginTop: 20
  },
  paymentInput: {
    height: 25,
    borderRadius: 3,
    border: "1px solid lightgray"
  },
  grayText: {
    marginTop: 20,
    color: "#232F3E",
    opacity: 0.5
  },
});
