import { makeStyles } from "@material-ui/styles";

export const OverlayWithLoaderStyle = makeStyles({
    overlay: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 2,
        cursor: "pointer"
      },
      overlayText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "20px",
        color: "white",
        transform: "translate(-50%,-50%)",
        textAlign: "center"
      }
});