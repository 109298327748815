import { makeStyles, withStyles } from "@material-ui/styles";
import { InputBase } from "@material-ui/core";

export const useSigninPageStyles = makeStyles({
  root: {
    height: "100vh",
    // eslint-disable-next-line quotes
    backgroundImage: 'url("https://contura-web-assets.s3.amazonaws.com/images/background_coals.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 0",
    zIndex: -2,
    backgroundColor: "rgba(33, 42, 54, .97)",
    color: "white",
  },
  contentContainer: {
    height: "40vh",
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "space-between",
    paddingTop: "25vh"
  },
  logo: {
    transform: "scale(.5)",
  },
  contura: {
    transform: "scale(.5)",
    marginLeft: "-3rem",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      height: "2rem", 
      maxHeight: "2rem",
      color: "#FFF",
      backgroundColor: "#2A78E4", //lightblue
      "&:focus": {
        outline: 0,
      },
    },
  },
  headingContainer: {
    "& div:last-of-type": {
      textAlign: "center",
    },
  },
  inputContainer: {
    height: 165
  },
  signinButton: {
    marginTop: ".5rem",
    height: "1.75rem", 
    maxHeight: "1.75rem",
    width: "5rem",
    color: "#FFF",
    backgroundColor: "#2A78E4", //lightblue
    "&:focus": {
      outline: 0,
    },
  },
  header: {
    fontSize: 46,
    marginTop: ".25rem",
    fontWeight: 100,
  },
  subText: {
    fontSize: 21,
  },
  inputRowLabel: {
    fontWeight: "500",
  },
  inputBox: {
    marginTop: "15px",
    height: "40px",
    width: "240px"  
  },
  inputBoxLarge: {
    marginTop: "15px",
    height: "40px",
    width: "360px"  
  },
  signin: {
    width: "240px !important",
    backgroundColor: "#52B1B5",
    margin: "0 auto",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#52B1B5",
    },
  },
});


export const BootstrapInput = withStyles(() => ({
  root: {
    "label + &": {
      marginTop: 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFF",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
