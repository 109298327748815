import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { useCompletedLocStyles } from "./styles";

export default function CompletedLettersOfCredits({ completedLocs, history }) {
  const classes = useCompletedLocStyles();
  const PAYMENT_AMOUNT = "paymentAmount";
  const LOC_ID = "locID";

  const handleLocDetails = (id) => {
    history.push({
      pathname: `/loc/${id}`,
    });
  };

  const columns = [
    { id: "locID", label: "Letter of Credit ID" },
    { id: "applicant", label: "Applicant" },
    { id: "descriptionOfGoods", label: "Goods" },
    { id: "paymentAmount", label: "Payment" },
    { id: "beneficiary", label: "Advising Bank" },
    { id: "closedDate", label: "Closed Date"},
  ];

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <h3 className={classes.title}><strong>Closed</strong> Letters of Credit</h3>
      </div>
      <div>
        <Table stickyHeader aria-label={"sticky table"}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.tableTitle}
                  key={column.id}
                  align={column.align}
                  style={{
                    width: column.id === PAYMENT_AMOUNT && "200px",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {completedLocs && completedLocs.length ?
              completedLocs.map((loc, idx) => {
                const { Record } = loc;
                return (
                  <TableRow key={Record.locID || idx}>
                    {columns.map((column, idx) => (
                      <TableCell key={`${Record.locID}+${idx}`} className={classes.tableCell}>
                        <span
                          onClick={() => column.id === LOC_ID && handleLocDetails(Record.locID)}
                          style={{
                            cursor: column.id === LOC_ID && "pointer",
                            color: column.id === LOC_ID && "#52B1B5",
                          }}
                        >
                          {Record[column.id] ? Record[column.id] : ""}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
              :
              null
            }
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

CompletedLettersOfCredits.propTypes = {
  completedLocs: PropTypes.array,
  history: PropTypes.object,
};