import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MessageSnackBar from "components/snackBar";
import {setSnackBarDisplay} from "store/actions";
import { HIDE_SNACKBAR } from "store/constants";

const GlobalSnackBar = () =>{
    const { snackBar } = useSelector((state) => state.displayReducer);
    const dispatch = useDispatch();

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        dispatch(setSnackBarDisplay(HIDE_SNACKBAR))
      };

    return(
        <MessageSnackBar
            open={snackBar.open}
            status={snackBar.response.status}
            message={snackBar.response.message}
            handleClose={handleSnackBarClose}
          />
    )
}

export default GlobalSnackBar