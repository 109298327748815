import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import {
  addLoc,
  clearLoc,
  fetchAllLocs,
} from "store/actions";

import ActiveLettersOfCredit from "App/LettersOfCredit/ActiveLocs";
import CompletedLettersOfCredits from "App/LettersOfCredit/CompletedLocs";
import NewLocModal from "App/NewLocModal";

import { useHomePageStyles } from "./styles";
import createIcon from "assets/createIcon.png";

export default function LettersOfCredit({ history }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [activeLocs, setActiveLocs] = React.useState([]);
  const [newLocId, setNewLocId] = React.useState(null);
  
  const COMPLETED = "completed";
  const classes = useHomePageStyles();
  const dispatch = useDispatch();
  
  const { locs } = useSelector((state) => state.locsReducer);
  const { transactionId } = useSelector((state) => state.locCreateReducer);
  
  React.useEffect(() => {
    dispatch(clearLoc());
    dispatch(fetchAllLocs());
  }, [dispatch]);

  const submitNewLoc = (locFile, locID) => {
    setNewLocId(locID);
    dispatch(addLoc(locID, locFile));
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    if (transactionId && newLocId !== null) {
      history.push({
        pathname: `/loc/${newLocId}`,
      });
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, newLocId]);

  // This handles seperating the active, or open, locs and sorts them by expiration date (desc) for the active locs table
  React.useEffect(() => {
    const sortActiveLocsByDate = () => {
      if (locs === null) {
        setActiveLocs(null);
        return;
      }
      if (locs.length) {
        const openLocsWithoutExpiryDate = locs.filter((loc) => loc.Record.status !== COMPLETED && (loc.Record.expiryDate === "" || loc.Record.expiryDate === null));
        const openLocsSortedByExpiryDate = locs.filter((loc) => loc.Record.status !== COMPLETED)
          .filter((loc) => loc.Record.expiryDate !== "" && loc.Record.expiryDate !== null)
          .sort((a, b) => {
            return new Date(a.Record.expiryDate) - new Date(b.Record.expiryDate);
          });

        setActiveLocs(openLocsSortedByExpiryDate.concat(openLocsWithoutExpiryDate));
      }
    };

    sortActiveLocsByDate();
  }, [locs]);

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <h2 className={classes.title}><strong>Active</strong> Letters Of Credit</h2>
        <Button onClick={() => setIsModalOpen(true)}
          variant="contained" 
          className={classes.createLetterButtonRoot}>
          <img src={createIcon} alt={"pdfIcon"} style={{width: "25px"}} />
          <span style={{fontSize: "16px"}}>New Letter of Credit</span>
        </Button>
      </div>
      {isModalOpen && (
        <NewLocModal
          isOpen={isModalOpen}
          history={history}
          width={350}
          handleClose={() => setIsModalOpen(false)}
          submitNewLoc={submitNewLoc}
        />
      )}
      <ActiveLettersOfCredit
        history={history}
        activeLocs={activeLocs}
      />
      <CompletedLettersOfCredits
        history={history}
        completedLocs={locs.length ? locs.filter((loc) => loc.Record.status === COMPLETED) : null}
      />
    </div>
  );
}

LettersOfCredit.propTypes = {
  history: PropTypes.object,
};