import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";

import ViewLoc from "App/LocDetails/ViewLoc";
import ViewDoc from "App/LocDetails/ViewDocument";
import DetailsPanel from "App/LocDetails/DetailsPanel";
import WorkflowPanel from "App/LocDetails/WorkflowPanel";
import EventsLog from "App/LocDetails/EventsLog";
import NewDocModal from "App/NewDocModal";
import NewApproveModal from "App/NewApproveModal";
import ConfirmPaymentModal from "App/LocDetails/ConfirmPaymentModal";
import uploadPdf from "assets/uploadPdf.png";

import { useDetailsStyles } from "./styles";
import {
  approvePresentationDocument,
  rejectPresentationDocument,
  addPresentationDocument,
  fetchLocDetails,
  preparePresentation,
  approvePresentation,
  rejectPresentation,
} from "store/actions";

import RestrictedAccess from "global/RolesWithRestrictedAccess";

export default function LocDetails({history}) {
  const [showLoc, setShowLoc] = React.useState(false);
  const [showDoc, setShowDoc] = React.useState(null);
  const [requiredDocs, setRequiredDocs] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [eventsLogOpen, setEventsLogOpen] = React.useState(false);
  const [isApproveModelOpen, setIsApproveModelOpen] = React.useState(false);
  const [approveModalMarker, setApproveModalMarker] = React.useState("");
  const [approveModalProps, setApproveModalProps] = React.useState(null);
  const [documentType, setDocumentType] = React.useState();
  const [isLocStatusOpen, setIsLocStatusOpen] = React.useState(true);
  const [approvalStatus, setApprovalStatus] = React.useState();
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] = React.useState(false);

  const classes = useDetailsStyles();
  
  const BILL_OF_LADING = "billOfLading";
  const CERT_OF_ANALYSIS = "certificateOfAnalysis";
  const COMMERCIAL_INVOICE = "commercialInvoice";
  const CERT_OF_WEIGHT = "certificateOfWeight";
  const CERT_OF_ORIGIN = "certificateOfOrigin";
  const OPEN = "open";
  
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loc } = useSelector((state) => state.locDetailsReducer);
  const { userGroup } = useSelector((state) => state.authReducer);
  const locTransactionId = useSelector((state) => state.locAmendmentReducer.transactionId);
  const presentationTransactionId = useSelector((state) => state.presentationDocumentReducer.transactionId);
  const locPresentationTransactionid = useSelector((state) => state.locPresentationReducer.transactionId);

  React.useEffect(() => {
    dispatch(fetchLocDetails(id));
  }, [id, dispatch, locTransactionId, presentationTransactionId, locPresentationTransactionid, saveBtnClicked]);
  
  React.useEffect(() => {
    const { presentationDocuments, status } = loc;
    let requiredDocuments = {};
    for (const doc in presentationDocuments) {
      requiredDocuments[doc] = true;
    }
    
    setRequiredDocs(requiredDocuments);
    setApprovalStatus(statusOrganitor(status));
  }, [loc]);

  React.useEffect(() => {
    if (Object.keys(loc).length !== 0) {
      if (loc.status !== OPEN) {
        setIsLocStatusOpen(false);
      }
    }
  }, [loc]);
  
  const statusOrganitor = status => {
    switch(status) {
    case "open":
      return {
        preparePresentation: false,
        approvePresentation: false,
        closePresentation: false
      };
    case "pendingPresentationReview":
      return {
        preparePresentation: true,
        approvePresentation: false,
        closePresentation: false
      };
    case "presentationApproved":
      return {
        preparePresentation: true,
        approvePresentation: true,
        closePresentation: false
      };
    case "presentationRejected":
      return {
        preparePresentation: true,
        approvePresentation: true,
        closePresentation: false
      };
    case "completed":
      return {
        preparePresentation: true,
        approvePresentation: true,
        closePresentation: true
      };
    default:
      return {
        preparePresentation: false,
        approvePresentation: false,
        closePresentation: false
      };
    }
  };

  const onToggleViewLoc = () => {
    setShowLoc(!showLoc);
  };
  
  const onToggleViewDoc = (docType) => {
    setShowDoc(docType);
  };

  const selectDocumentType = (documentType) => {
    setDocumentType(documentType);
    setIsModalOpen(true);
  };

  const uploadDocument = (document) => {
    if (documentType && id && document) {
      dispatch(addPresentationDocument(id, documentType, document));
    }
    
    setIsModalOpen(false);
  };

  const handlePreparePresentation = () => {
    setApproveModalProps(modalOriginator("Prepare Presentation"));
    setApproveModalMarker("Prepare Presentation");
  };

  const handlePreparePresentationBtnClick = () => {
    dispatch(preparePresentation(id));
    setApproveModalMarker(null);
  };

  const handleCancelPrepareBtnClick = () => {
    setApproveModalMarker(null);
  };

  const handleApprovePresentation = () => {
    setApproveModalProps(modalOriginator("Approve Presentation"));
    setApproveModalMarker("Approve Presentation");
  };

  const handleApprovePresentationBtnClick = () => {
    dispatch(approvePresentation(id));
    setApproveModalMarker(null);
  };

  const handleRejectPresentationBtnClick = () => {
    dispatch(rejectPresentation(id));
    setApproveModalMarker(null);
  };

  const handleConfirmPayment = () => {
    setIsConfirmPaymentModalOpen(true);
  };

  const handleModalClose = () => {
    setApproveModalMarker(null);
  };

  React.useEffect(() => {
    approveModalMarker
      ?
      setIsApproveModelOpen(true)
      :
      setIsApproveModelOpen(false);
  },[approveModalMarker]);

  const modalOriginator = (marker) => {
    switch(marker) {
    case "Prepare Presentation":
      return {
        handleApprove: handlePreparePresentationBtnClick,
        handleReject: handleCancelPrepareBtnClick,
        headerText: "Prepare & send presentation",
        approveButtonText: "Send",
        rejectButtonText: "Cancel",
        content: (
          <div>
            Ready to send Regions Bank a notification
            that the documents are ready for their 
            review?
          </div>
        ),
      };
    case "Approve Presentation":
      return {
        handleApprove: handleApprovePresentationBtnClick,
        handleReject: handleRejectPresentationBtnClick,
        headerText: "Approve Presentation",
        approveButtonText: "Approve",
        rejectButtonText: "Reject",
        content: (
          <div>
            <Grid container>
              <Grid item xs={12}>
                <div> 
                  To review the presentation docs, click the 
                  &apos;View&apos; link next to each doc in the Workflow pane.
                </div>
              </Grid>
              <Grid item xs={12}>
                <br/>
              </Grid>
              <Grid item xs={12}>
                <div> 
                  Then, return here to approve or reject.
                </div>
              </Grid>
            </Grid>
          </div>
        ),
      };
    default:
      return null;
    }
  };

  const handleViewEventsLogClick = () =>{
    setEventsLogOpen(true);
  };

  const handleCloseEventsLog = () =>{
    setEventsLogOpen(false);
  };

  const approveDocument = (docType) => {
    if (id && docType) {
      dispatch(approvePresentationDocument(id, docType));
    }
  };

  const rejectDocument = (docType) => {
    if (id && docType) {
      dispatch(rejectPresentationDocument(id, docType));
    }
  };

  const chooseHeaderText = () => {
    if (documentType === BILL_OF_LADING) {
      return "Upload the Bill of Lading";
    } else if (documentType === CERT_OF_ANALYSIS) {
      return "Upload the Certificate of Analysis";
    } else if (documentType === COMMERCIAL_INVOICE) {
      return "Upload the Commercial Invoice";
    } else if (documentType === CERT_OF_WEIGHT) {
      return "Upload the Certificate of Weight";
    } else if (documentType === CERT_OF_ORIGIN) {
      return "Upload the Certificate of Origin";
    }
  };

  const chooseDescriptionText = () => {
    if (documentType === BILL_OF_LADING) {
      return (
        <div className={classes.modalContent}>
          <p><img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"} /></p>
          <p className={classes.textInDropZone}>Drop a signed</p>
          <p className={classes.textInDropZone}>Bill of Lading here</p> 
          <p >or <span className={classes.greenText}>Choose File</span></p>
        </div>
      );
    } else if (documentType === CERT_OF_ANALYSIS) {
      return (
        <div className={classes.modalContent}>
          <p><img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"} /></p>
          <p className={classes.textInDropZone}>Drop a signed</p>
          <p className={classes.textInDropZone}>Certificate of Analysis here</p> 
          <p className={classes.textInDropZone}>
            or <span className={classes.greenText}>Choose File</span>
          </p>
        </div>
      );
    } else if (documentType === COMMERCIAL_INVOICE) {
      return (
        <div className={classes.modalContent}>
          <p><img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"} /></p>
          <p className={classes.textInDropZone}>Drop a signed</p>
          <p className={classes.textInDropZone}>Commercial Invoice here</p> 
          <p className={classes.textInDropZone}>
            or <span className={classes.greenText}>Choose File</span>
          </p>
        </div>
      );
    } else if (documentType === CERT_OF_WEIGHT) {
      return (
        <div className={classes.modalContent}>
          <p><img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"} /></p>
          <p className={classes.textInDropZone}>Drop a signed</p>
          <p className={classes.textInDropZone}>Certificate of Weight here</p> 
          <p className={classes.textInDropZone}>
            or <span className={classes.greenText}>Choose File</span>
          </p>
        </div>
      );
    } else if (documentType === CERT_OF_ORIGIN) {
      return (
        <div className={classes.modalContent}>
          <p><img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"} /></p>
          <p className={classes.textInDropZone}>Drop a signed</p>
          <p className={classes.textInDropZone}>Certificate of Origin here</p> 
          <p className={classes.textInDropZone}>
            or <span className={classes.greenText}>Choose File</span>
          </p>
        </div>
      );
    }
  };

  const restrictedAccessOnFunctions = (func, action) => {
    return RestrictedAccess.WorkflowPanel[action].includes(userGroup)?
      () => {
        return;
      }
      : func;
  };

  return (
    <Grid container className={classes.root}>
      {eventsLogOpen && (
        <EventsLog 
          open={eventsLogOpen}
          handleCloseEventsLog={handleCloseEventsLog}
        />
      )}
      {isModalOpen && (
        <NewDocModal
          isOpen={isModalOpen}
          width={350}
          handleClose={() => setIsModalOpen(false)}
          handleSubmitDoc={uploadDocument}
          headerText={chooseHeaderText()}
        >
          {chooseDescriptionText()}
        </NewDocModal>
      )}
      {isConfirmPaymentModalOpen && (
        <ConfirmPaymentModal
          id={id}
          history={history}
          isOpen={isConfirmPaymentModalOpen}
          handleClose={() => setIsConfirmPaymentModalOpen(false)}
        />
      )}
      {(isApproveModelOpen && approveModalProps)&& (
        <NewApproveModal
          isOpen={isApproveModelOpen}
          width={350}
          handleClose={handleModalClose}
          headerText={approveModalProps.headerText}
          approveButtonText={approveModalProps.approveButtonText}
          rejectButtonText={approveModalProps.rejectButtonText}
          handleApprove={approveModalProps.handleApprove}
          handleReject={approveModalProps.handleReject}
          content={approveModalProps.content}
        />
      )}
      <div className={classes.leftContainer}>
        <DetailsPanel 
          userGroup={userGroup}
          isLocStatusOpen={isLocStatusOpen}
          handleViewEventsLogClick={handleViewEventsLogClick}
          onSaveLoc={() => setSaveBtnClicked(!saveBtnClicked)}
        />
      </div>
      <div className={classes.rightContainer}>
        {!showLoc && !showDoc && (
          <WorkflowPanel
            handleViewLoc={onToggleViewLoc}
            handleViewDoc={onToggleViewDoc}
            requiredDocs={requiredDocs}
            onUploadDocument={restrictedAccessOnFunctions(selectDocumentType, "uploadDocument")}
            handlePreparePresentation={restrictedAccessOnFunctions(handlePreparePresentation, "preparePresentation")}
            handleApprovePresentation={restrictedAccessOnFunctions(handleApprovePresentation, "approvePresentation")}
            handleConfirmPayment={restrictedAccessOnFunctions(handleConfirmPayment, "confirmPayment")}
            approvalStatus={approvalStatus}
          />
        )}
        {showLoc && (
          <ViewLoc handleCloseLoc={onToggleViewLoc} isLocStatusOpen={isLocStatusOpen} />
        )}
        {showDoc && (
          <ViewDoc
            docType={showDoc}
            approveDocument={approveDocument}
            rejectDocument={rejectDocument}
            handleCloseDoc={() => setShowDoc(null)}
            isLocStatusOpen={isLocStatusOpen}
          />
        )}
      </div>
    </Grid>
  );
}

LocDetails.propTypes = {
  history: PropTypes.object,
};