import { createStyles, makeStyles } from "@material-ui/styles";

export const useModalStyles = makeStyles(() =>
  createStyles({
    createButton: {
      width: "100%",
      height: 45,
      fontSize: "16px",
      color: "white",
      backgroundColor: "#52B1B5",
      textTransform: "none",
      "&:focus": {
        backgroundColor: "#52B1B5",
        opacity: 0.8,
      },
      "&:hover": {
        backgroundColor: "#52B1B5",
      },
    },
    locIdInput: {
      border: "1px solid lightgray",
      width: 200,
      height: 25,
      borderRadius: 5,
      "&:active": {
        outlineColor: "#52B1B5",
      },
      "&:focus": {
        outlineColor: "#52B1B5",
      },
    },
    inputSection: {
      display: "inline-block !important",
      margin: ".5rem auto 1rem auto !important",
    },
    inputContainer: {
      marginTop: ".5rem",
    },
    buttonContainer: {
      backgroundColor: "#FFF",
      padding: "15px 50px",
    },
    label: {
      textTransform: "uppercase",
      fontSize: "14px",
    },
  })
);
