import {
    RECEIVE_LOCS,
    REQUEST_LOCS,
  } from "store/constants";
  
  const initialState = {
    locs: [],
    isFetching: false,
  };
  
  export const locsReducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch(type) {
    case RECEIVE_LOCS: 
      return {
        ...state,
        isFetching: false,
        locs: payload.locs,
      };
    case REQUEST_LOCS:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
    }
  };
  