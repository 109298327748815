import React from "react";
import PropTypes from "prop-types";
import { Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SimpleModal from "../../components/modal";

import { NewApproveModalStyle } from "./styles";

export default function NewApproveModal({
  handleClose,
  handleApprove,
  handleReject,
  isOpen,
  width,
  headerText,
  approveButtonText,
  rejectButtonText,
  content,
}) {
  const classes = NewApproveModalStyle();
  const modalContent = (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.contentContainer}>
          {content}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonsContainer}>
          <Grid container direction="row" justify="space-between" spacing={1}>
            <Grid item xs={6}>
              <Button variant="contained" className={classes.approveButton} onClick={handleApprove}>{approveButtonText}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className={classes.rejectButton} onClick={handleReject}>{rejectButtonText}</Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
  return (
    <SimpleModal
      isOpen={isOpen}
      handleClose={handleClose}
      width={width}
      headerText={headerText}
      content={modalContent}
    />
  );
}

NewApproveModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
  headerText: PropTypes.string,
  handleApprove: PropTypes.func,
  handleReject: PropTypes.func,
  approveButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

