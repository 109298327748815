import { config } from "config";
import { sendRequest } from "./http";

export const createPresentationDocument = async(locID, documentType, file) => {
    const URL = config.baseURL + "locs/" + locID + "/documents/" + documentType;
    const headers = {"content-type": "application/pdf"};
    return await sendRequest("POST", URL, file, headers);
};

export const approvePresentationDocument = async(locID, documentType) => {
    const URL = config.baseURL + "locs/" + locID + "/documents/" + documentType + "/approve";
    return await sendRequest("POST", URL);
};

export const rejectPresentationDocument = async(locID, documentType) => {
    const URL = config.baseURL + "locs/" + locID + "/documents/" + documentType + "/reject";
    return await sendRequest("POST", URL);
};