import React from "react";
import PropTypes from "prop-types";
import AWS from "aws-sdk";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, NativeSelect } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Loader from "react-loader-spinner";
import uploadPdf from "assets/uploadPdf.png";
import NewDocModal from "App/NewDocModal";

import { uploadAmendmentDocument } from "store/actions";
import { useViewDocStyles, BootstrapInput } from "./styles";

import { config } from "config";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewLoc({ handleCloseLoc, isLocStatusOpen }){
  const dispatch = useDispatch();
  const { loc } = useSelector((state) => state.locDetailsReducer);
  const PREV = "Prev";
  const NEXT = "Next";
  const ORIGINAL = "Original";

  const [pageNum, setPageNum] = React.useState(1);
  const [numOfPages, setNumOfPages] = React.useState(null);
  const [showAmendmentModal, setShowAmendmentModal] = React.useState(false);
  const [s3SignedURL, setS3SignedURL] = React.useState(null);
  const [locVersion, setLocVersion] = React.useState(ORIGINAL);
  const [currentLocSelected, setCurrentLocSelected] = React.useState();
  
  const classes = useViewDocStyles();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  React.useEffect(() => {
    setPageNum(1);
  }, [locVersion]);

  React.useEffect(() => {
    const getSignedURL = async () => {
      const documentBucket = config.documentBucket;
      const bucketURL = "https://" + documentBucket + ".s3.amazonaws.com/";
      const s3Key = locVersion === ORIGINAL 
        ? loc.swiftTransactionDocuments["SWIFT700"].versions[0]["contentURL"].slice(bucketURL.length)
        : loc.swiftTransactionDocuments["SWIFT707"].versions[locVersion]["contentURL"].slice(bucketURL.length);
      const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
      const params = {Bucket: documentBucket, Key: s3Key};
      const signedURL = await s3.getSignedUrlPromise("getObject", params);
      setS3SignedURL(signedURL);
    };

    getSignedURL();
  }, [loc, locVersion]);

  React.useEffect(() => {
    const findCurrentLocSelected = locVersion === ORIGINAL
      ? loc.swiftTransactionDocuments["SWIFT700"].versions[0]
      : loc.swiftTransactionDocuments["SWIFT707"].versions[locVersion];

    setCurrentLocSelected(findCurrentLocSelected);
  }, [loc, locVersion]);

  const handlePageChange = (direction) => {
    let page;
    if (direction === PREV) {
      page = Math.max(1, pageNum - 1);
    } else if (direction === NEXT) {
      page = Math.min(numOfPages, pageNum + 1);
    }
    setPageNum(page);
  };

  if (!loc) {
    return null;
  }

  const onUploadAmendment = (document) => {
    const { locID } = loc;
    if (document && locID) {
      dispatch(uploadAmendmentDocument(locID, document));
    }

    setShowAmendmentModal(false);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString([], {hour: "numeric", minute: "2-digit"});
  };
 
  return (
    <div className={classes.root}>
      {showAmendmentModal && (
        <NewDocModal
          width={350}
          isOpen={showAmendmentModal}
          headerText={"Upload Letter of Credit Amendment"}
          handleClose={() => setShowAmendmentModal(false)}
          handleSubmitDoc={onUploadAmendment}
        >
          <div className={classes.modalContent}>
            <p><img src={uploadPdf} className={classes.pdfIcon} alt=""/></p>
            <p className={classes.textInDropZone}>Drop a new</p>
            <p className={classes.textInDropZone}>Letter of Credit here</p> 
            <p className={classes.textInDropZone}>
              or <span className={classes.greenText}>Choose File</span>
            </p>
          </div>
        </NewDocModal>
      )}
      <div className={classes.headerActions}>
        <button className={classes.goBack} onClick={handleCloseLoc}> 
          <span className={classes.arrowIconContainer}> 
            <ArrowBackIosIcon className={classes.arrowIcon} /> 
          </span> 
          Back 
        </button> 
        <FormControl className={classes.formMargin}> 
          <NativeSelect 
            id="locVersionSelector" 
            value={locVersion} 
            onChange={(e) => setLocVersion(e.target.value)} 
            input={<BootstrapInput />} 
          > 
            <option value={ORIGINAL}>Original</option> 
            {loc.swiftTransactionDocuments.SWIFT707.versions.length > 0 &&
              loc.swiftTransactionDocuments.SWIFT707.versions.map((ele, idx) => ( 
                <option key={idx} value={idx}>Amendment {idx + 1}</option> 
              ))} 
          </NativeSelect> 
        </FormControl> 
      </div> 

      <div className={classes.contentContainer}>
        {s3SignedURL && (
          <>
            <div className={classes.pdfWrapper}>
              <Document
                file={s3SignedURL}
                loading={
                  <Loader
                    type={"Oval"}
                    color={"#52B1B5"}
                    height={50}
                    width={50}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50%",
                    }}
                  />
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNum} />
              </Document>
            </div>
            <div className={classes.docNavigation}>
              {numOfPages && (
                <>
                  <ArrowLeftIcon
                    className={pageNum !== 1 ? classes.navIcon : classes.disabledNavIcon}
                    onClick={() => handlePageChange(PREV)}
                  />
                  <input
                    type={"number"}
                    className={classes.pagesInput}
                    value={pageNum}
                    readOnly
                  />
                  <span>of {numOfPages} pages</span>
                  <ArrowRightIcon
                    className={pageNum !== numOfPages ? classes.navIcon : classes.disabledNavIcon}
                    onClick={() => handlePageChange(NEXT)}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div className={classes.docInfo}>
        <div>
          <h4>Document</h4>
          <span>Letter of Credit</span>
        </div>
        <div>
          <h4>Uploader</h4>
          <span>{currentLocSelected && currentLocSelected.createdBy}</span>
        </div>
        <div>
          <h4>Uploaded</h4>
          <span>{currentLocSelected && formatDate(currentLocSelected.createdAt)}</span>
        </div>
      </div>
      <div>
        {isLocStatusOpen && (
          <button
            className={classes.amendmentButton}
            onClick={() => setShowAmendmentModal(true)}
          >
            Upload Amendment
          </button>
        )}
      </div>
    </div>
  );
}

ViewLoc.propTypes = {
  handleCloseLoc: PropTypes.func,
  isLocStatusOpen: PropTypes.bool,
};
