import { makeStyles } from "@material-ui/styles";

export const useHeaderStyles = makeStyles(theme => ({
  root: {
    color: "#FFF",
    height: theme.headerHeight,
    display: "flex",
    backgroundColor: "#171E2D",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
  },
  logoArea: {
    width: 230,
    display: "flex",
    alignItems: "center",
    "& div": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  logo: {
    transform: "scale(.35)",
  },
  contura: {
    transform: "scale(.3)",
    marginLeft: "-5rem",
    paddingTop: "-2rem"
  },
  logoContainer: {
    width: 90,
    marginLeft: 15,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    fontSize: "0.8rem"
  },
  accountIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  hr :{
    border: 0,
    borderTop: "2px solid #e6e6e6",
    margin: 0
  },
  popoverText: {
    margin: 0
  },
  grayText: {
    color: "gray",
    marginBottom: 0,
    marginTop: 10
  },
  popoverRow: {
    padding: "15px 20px",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F8F8F9",
    }
  },
  popoverContent: {
    width: 250,
    marginTop: 10
  },
  link: {
    cursor: "pointer"
  },
  userIcon: {
    width: 28,
    height: 28,
  }
}));
