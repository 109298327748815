import {
  SHOW_OVERLAY,
  HIDE_OVERLAY,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
} from "store/constants";
  
const initialState = {
  overlayDisplay: "none",
  snackBar: {
    open: false,
    response: {
      status: "",
      message: ""
    }
  }
};

export const displayReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch(type) {
  case SHOW_OVERLAY:
    return {
      ...initialState,
      overlayDisplay: "block"
    };
  case HIDE_OVERLAY:
    return {
      ...initialState,
      overlayDisplay: "none"
    };
  case SHOW_SNACKBAR:
    return {
      ...initialState,
      snackBar: {
        open: true,
        response: {
          message: payload.message,
          status: payload.status
        }
      }
    };
  case HIDE_SNACKBAR:
    return {
      ...initialState,
      snackBar: {
        open: false,
        response: {
          status: "",
          message: ""
        }
      }
    };
  default:
    return state;
  }
};
  