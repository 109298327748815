/**
 * THIS IS AN INTERNAL LIBRARY, DON'T require THIS.  require index INSTEAD
 */
import axios from "axios";
import { SHOW_OVERLAY, HIDE_OVERLAY, SHOW_SNACKBAR }from "../store/constants/index";
import { setOverlayDisplay, setSnackBarDisplay } from "../store/actions/index";
import { store } from "../store/index";
import { getIdentityToken } from "auth";

function APIException(status, message) {
  this.status = status;
  this.message = message;
  this.name = 'APIException';
}

export async function sendRequest(method, url, data, headers) {
  headers = headers || {};
  let identityToken = await getIdentityToken(false);
  headers["Authorization"] = identityToken;

  if(method === "POST"||method === "PUT"){
    store.dispatch(setOverlayDisplay(SHOW_OVERLAY));
  }

  let response = await axios({
    method,
    url,
    data,
    headers
  }).catch(err => {
    console.log("API HTTP: err is ", err);
    console.log("API HTTP: err.response is ", err.response);
    let status = err.response.status;
    let errorData = err.response.data;

    // error message will be structured differently depending where it came from
    let message;
    
    if (errorData.message) {
      // Lambda non-proxy errors
      message = errorData.message;
    } else if (errorData.errorMessage) {
      // Fabric Lambda errors
      // parse out "transaction returned with failure: "
      message = errorData.errorMessage.slice("transaction returned with failure: ".length);
    } else {
      // Lambda proxy errors, i.e. all the file uploads
      message = errorData.split(":")[1];
    }

    store.dispatch(setOverlayDisplay(HIDE_OVERLAY));
    store.dispatch(
      setSnackBarDisplay(
        SHOW_SNACKBAR, 
        {
          status: "error", 
          message: message
        }
      )
    )
    throw new APIException(status, message);
  })

  const result = response.data;
  const txId = result.putResult 
    ? JSON.parse(result.putResult.Payload).transactionId 
    : result.transactionId

  if( txId ){
    store.dispatch(setOverlayDisplay(HIDE_OVERLAY))
  }

  return response 
}
