import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from "amazon-cognito-identity-js";
import jwt from "jsonwebtoken";
import AWS from "aws-sdk";
import { config } from "config";

// Very helpful: https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js

export function userIsLoggedIn() {
  let cognitoUser = getCurrentUser();
  return !!cognitoUser;
}

export function logout() {
  let cognitoUser = getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
    AWS.config.credentials.clearCachedId();
  }
}

export const attemptSignin = async(username, password) => {
  
    const appClientId = config.cognitoAppClientId;
    const cognitoPoolId = config.cognitoPoolId;
    
    const poolData = {
      UserPoolId : cognitoPoolId,
      ClientId : appClientId
    };
    
    const userPool = new CognitoUserPool(poolData);
    
    let userData = {
      Username : username,
      Pool : userPool
    };
    
    const cognitoUser = new CognitoUser(userData);
    
    const authDetails = new AuthenticationDetails({
      Username : username,
      Password : password,
    });
    
    return new Promise( function (resolve, reject) {
      
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: function (result) {
          let identityToken = result.getIdToken().getJwtToken();
          let identityTokenDecoded = jwt.decode(identityToken);
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
              [config.cognitoIdentityProviderName]: result
              .getIdToken()
              .getJwtToken(),
            }
          });

          AWS.config.credentials.refresh(err => {
            if (err) {
              console.log("Error refreshing credentials ", err);
              return reject(err.message);
            }
            return resolve({data: {identityToken, identityTokenDecoded}});
          });

        },
        
        onFailure: function(err) {
          console.log("API Auth Error: ", err);
          let message = "Username / password did not match.  Please try again."
          return reject(message);
        }
      });
    })
  };

  export function getCurrentUser() {
    const userPool = new CognitoUserPool({
      UserPoolId : config.cognitoPoolId,
      ClientId : config.cognitoAppClientId
    });

    return userPool.getCurrentUser();
  }

export function getUserAttributes() {
  return new Promise( function (resolve, reject) {
    let cognitoUser = getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(function(err, session) {
        if (err) {
            return reject(err.message || JSON.stringify(err));
        }
    
        cognitoUser.getUserAttributes(function(err, result) {
          if (err) {
            return reject(err.message || JSON.stringify(err));
          }

          let attrs = {};
          const ATTRIBUTE_NAMES = ["given_name", "family_name", "email"];
          for (let i = 0; i < result.length; i++) {
            if (ATTRIBUTE_NAMES.includes(result[i].getName())) {
              attrs[result[i].getName()] = result[i].getValue();
            }
          }

          return resolve(attrs);
        });
      });
    } else {
      return resolve({});
    }
  });
}

// Pass in 'decode' boolean to decode token
export async function getIdentityToken(decode) {
  let cognitoUser = getCurrentUser();

  if (!cognitoUser) return null;

  return new Promise( function (resolve, reject) {
    cognitoUser.getSession(function(err, session) {
      if (err) {
        return reject(err.message || JSON.stringify(err));
      }

      let token = session.getIdToken().getJwtToken();
      return resolve(decode ? jwt.decode(token) : token);
    });
  })
};

export async function setAWSCredentials() {
  let token = await getIdentityToken();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.identityPoolId,
    Logins: {
      [config.cognitoIdentityProviderName]: token
    }
  });
  await AWS.config.credentials.refresh();
}

export function decodeIdentityToken(token) {
  return jwt.decode(token);
}

export function refreshTokens() {
  let cognitoUser = getCurrentUser();
  let session = cognitoUser.getSession();

  let refresh_token = session.getRefreshToken();
  
  if (AWS.config.credentials.needsRefresh()) {
    cognitoUser.refreshSession(refresh_token, (err, session) => {
      if (err) {
        console.log(err);
      } else {
        AWS.config.credentials.params.Logins[config.cognitoIdentityProviderName] = session.getIdToken().getJwtToken();
        AWS.config.credentials.refresh(err => {
          if (err) {
            console.log(err);
          } else {
            console.log('auth: refresh token updated');
          }
        });
      }
    });
  }
}