/* eslint-disable no-prototype-builtins */
import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useRequiredDocsStyles } from "./styles";
import flagged from "assets/flagged.png";
import unflagged from "assets/unflagged.png";

export default function RequiredDocs({ handleReqDocumentChanges, handleFlagDocumentChanges, requiredDocs, modifiedRequiredDocs, flaggedDocs, modifiedFlaggedDocs }) {
  const classes = useRequiredDocsStyles();

  const { loc } = useSelector((state) => state.locDetailsReducer);

  const combinedRequiredDocs = modifiedRequiredDocs
    ? Object.assign(requiredDocs, modifiedRequiredDocs) 
    : requiredDocs
  const combinedFlaggedDocs = modifiedFlaggedDocs
    ? Object.assign(flaggedDocs, modifiedFlaggedDocs)
    : flaggedDocs
  const shouldDisable = loc.status !== "open" && loc.status !== "presentationRejected"

  const requiredDocuments = [
    {label: "Certificate of Analysis", propName: "certificateOfAnalysis"},
    {label: "Bill of Lading", propName: "billOfLading"},
    {label: "Commerical Invoice", propName:  "commercialInvoice"},
    {label: "Certificate of Weight", propName: "certificateOfWeight"},
    {label: "Certificate of Origin", propName: "certificateOfOrigin"}]

  const checkingFlaggedDocs = doc => {
    return combinedRequiredDocs[doc]
    ? (
        <span 
          className={shouldDisable ? classes.disabledConditions : classes.conditions} 
          onClick = {() => {return shouldDisable ? null : handleFlagDocumentChanges(doc)}}>
          <img 
            src={combinedFlaggedDocs[doc] ? flagged : unflagged} 
            alt={"flagIcon"} 
            className={classes.flagIcon} />
          Conditions
        </span>
      )
    : null
  }
  
  return (
    combinedRequiredDocs ? (
      <Grid 
        container 
        justify="center" 
        direction="column" 
        alignItems="flex-start" 
        spacing={0} 
        className={classes.reqDocuments}>
        <Typography className={classes.title}>REQUIRED DOCUMENTS</Typography>
          {
            requiredDocuments.map((document, idx) => {
              return (
                <div key={idx}>
                  <input
                    type={"checkbox"}
                    name={document.propName}
                    checked={!!combinedRequiredDocs[document.propName]}
                    onChange={handleReqDocumentChanges}
                    disabled={shouldDisable}
                  />
                  <span className={classes.documentName}>{document.label}</span>
                  {checkingFlaggedDocs(document.propName)}
                </div>
              )
            })
          }
      </Grid> ) 
      : null
  );
}

RequiredDocs.propTypes = {
  handleFlagDocumentChanges: PropTypes.func, 
  modifiedRequiredDocs: PropTypes.object,
  flaggedDocs: PropTypes.object, 
  modifiedFlaggedDocs: PropTypes.object,
  handleReqDocumentChanges: PropTypes.func,
  requiredDocs: PropTypes.object,
};
