import React from "react";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";

const SimplePopover= ({
  anchorEl,
  handleClose,
  content
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
    return (
      <div>
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {content}
        </Popover>
      </div>
    );
}

export default SimplePopover

SimplePopover.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};