/* eslint-disable quotes */
import { makeStyles } from "@material-ui/styles";

export const useDetailsPanelStyles = makeStyles(theme => ({
  root: {
    "& input": {
      fontSize: "14"
    },
    fontSize: "16",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  leftContainer: {
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2vw 3vw 2rem 3vw",
    "& h6": {
      margin: "1rem 0"
    },
  },
  numOffset: {
    margin: "0 1rem 0 1.75rem",
    textTransform: "lowercase",
    opacity: "0.5"
  },
  textOffset: {
    marginTop: "10px",
    textTransform: "lowercase",
    opacity: "0.5",
    fontSize: 13,
    textAlign: "center"
  },
  lcLabelOffset: {
    marginLeft: "12px"
  },
  paymentLabelOffset: {
    marginLeft: "0.8vw"
  },
  mutedText: {
    color: "#878D95"
  },
  numberInput: {
    marginTop: "15px",
    height: "40px",
    "& input": {
      width: 45,
      paddingRight: 10,
    },
  },
  reqDocuments: {
    marginTop: "3rem",
    "& div": {
      marginTop: ".5rem",
      "& span": {
        marginLeft: ".5rem",
      },
    },
  },
  inputRow: {
    marginBottom: 10
  },
  descriptionOfGoodsRow: {
    display: "flex",
    alignItems: "flex-end",
    height: 40,
  },
  inputRowLabel: {
    fontSize: "calc(0.54rem + 2px)",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  emptyLabel: {
    visibility: "hidden"
  },
  inputBox: {
    border: "1px solid lightgray",
    borderRadius: 3,
    padding: 5,
    marginTop: "10px",
    height: "1.1875rem",
    width: "70%",
    "&:focus": {
      outline: "2px solid #52B1B5"
    }
  },
  inputBoxLarge: {
    height: "2vh",
    width: "70%",
    "&:focus": {
      outline: "2px solid #52B1B5"
    }  
  },
  textArea:{
    border: "1px solid lightgray",
    borderRadius: 3,
    padding: 5,
    marginTop: "10px",
    height: "60px",
    width: "80%",
    resize: "none",
    "&:focus":{
      outline: "2px solid #52B1B5"
    }
  },
  select: {
    border: "1px solid lightgray",
    borderRadius: 3,
    padding: 5,
    marginTop: "10px",
    height: "1.95rem",
    width: "90%",
    "&:focus": {
      outline: "2px solid #52B1B5"
    }
  },
  lastRow: {
    marginLeft: 0,
    justifyContent: "space-between",
    "& div:first-of-type": {
      marginLeft: 0,
    },
    "& input": {
      fontSize: "14px",
    },
  },
  actionBar: {
    height: 75,
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "white",
    boxShadow: "-1px -3px 8px #d9d9d9"
  },
  actionButtonContainer: {
    marginLeft: "3vw",
    width: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",

  },
  productFlexContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  save: {
    height: "50px",
    width: "100px",
    color: "#FFF",
    borderRadius: "6px",
    fontSize: "14px",
    backgroundColor: "#52B1B5",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#52B1B5",
      opacity:0.9
    },
  },
  eventsLogBtn: {
    height: "50px",
    width: "170px",
    color: "#52B1B5",
    fontSize: "14px",
    borderRadius: "6px",
    textTransform: "none",
    "&:focus": {
      outline: 0,
    },
    marginLeft: "1rem"
  },
  pageHeader: {
      fontSize: "1.5rem",
      marginBottom: 10,
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
  },
  detailsHeader: {
      fontSize: "1.2rem",
      marginBottom: 10
  },
  datePicker: {
    width: "133px", 
    height: "32px",
    marginTop: "10px", 
    marginBottom: 0,
    fontSize: "30px"
  },
  closeButton: {
    width: "1.9rem",
    height: "1.9rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "lightgray",
      borderRadius: 2
    }
  },
  closeIcon: {
    width: "1.8rem",
    height: "1.8rem"
  },
  grayText: {
    marginTop: 20,
    color: "#878D95",
    opacity: 0.5
  },
  paymentFlexContiner: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  flexContainer: {
    width: "98%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

