/* eslint-disable no-useless-escape */
import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";

import DateFnsUtils from "@date-io/date-fns";
import { Grid, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import NewApproveModal from "App/NewApproveModal";

import RequiredDocs from "App/LocDetails/RequiredDocs";
import RestrictedAccess from "global/RolesWithRestrictedAccess";
import { useSelector, useDispatch} from "react-redux";
import { loc as locAPI } from "api";
import { useDetailsPanelStyles } from "./styles";
import Button from "@material-ui/core/Button";
import closeIcon from "assets/close.png";
import {SHOW_SNACKBAR} from "store/constants";
import {setSnackBarDisplay} from "store/actions";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const datepickerTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: 14,
        border: "1px solid lightgray",
        background: "white",
        borderRadius: 3,
        paddingLeft: "5px",
        "&$focused":{
          border: "2px solid #52B1B5"
        }
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          display: "none"
        },
        "&:after": {
          display: "none"
        },
      }
    }
  },
});

const DetailsPanel = ({
  history,
  userGroup,
  handleViewEventsLogClick,
  isLocStatusOpen,
  onSaveLoc,
}) => {
  const classes = useDetailsPanelStyles();
  const dispatch = useDispatch();
  const [localLoc, setLoc] = React.useState({});
  const [modifiedLoc, setModifiedLoc] = React.useState({});
  const [requiredDocs, setRequiredDocs] = React.useState(null);
  const [modifiedRequiredDocs, setModifiedRequiredDocs] = React.useState(null);
  const [flaggedDocs, setFlaggedDocs] = React.useState(null);
  const [modifiedFlaggedDocs, setModifiedFlaggedDocs] = React.useState(null);
  const [touched, setTouched] = React.useState(false);
  const [isApproveModelOpen, setIsApproveModelOpen] = React.useState(false);
  
  const { loc } = useSelector((state) => state.locDetailsReducer);
  
  React.useEffect(() => {
    setLoc(loc);
  }, [loc]);

  React.useEffect(() => {
    if(loc.status === "pendingPresentationReview"){
      setModifiedLoc({})
      setModifiedRequiredDocs(null)
      setModifiedFlaggedDocs(null)
      setTouched(false)
    }
  },[loc.status])

  // This looks at the letter of credit and takes all presentational documents and assigns them to an object with a true value.
  // which is needed to drive the required documents checkboxes states
  React.useEffect(() => {
    const { presentationDocuments } = loc;
    let requiredDocuments = {};
    let flaggedDocs = {};
    for (const doc in presentationDocuments) {
      requiredDocuments[doc] = true;
      if (presentationDocuments[doc].hasConditions) 
        flaggedDocs[doc] = true;
    }
    setRequiredDocs(requiredDocuments);
    setFlaggedDocs(flaggedDocs);
  }, [loc]);

  const handleDateChange = (date,propName) => {
    setModifiedLoc({
      ...modifiedLoc,
      [propName]:date
    });
    setTouched(true);
  };

  const handleTextAreaFormatting = (str) => {
    return JSON.stringify(str)
      .replace(/[\\]/g, "\\\\")
      .replace(/[\"]/g, "\\\"")
      .replace(/[\/]/g, "\\/")
      .replace(/[\b]/g, "\\b")
      .replace(/[\f]/g, "\\f")
      .replace(/[\n]/g, "\\n")
      .replace(/[\r]/g, "\\r")
      .replace(/[\t]/g, "\\t");
  };

  const formatEscapedChars = (str) => {
    return str.replace(/\"/g, "").replace(/\\n/g, "\n").replace(/\\/g, "");
  };

  const handleChange = (event) => {
    setTouched(true);
  };

  const handleBlur = event => {
    const { target: { name, value } } = event;
    setModifiedLoc({
      ...modifiedLoc,
      [name]: value,
    })
  }
  
  const updateRequiredDocKeys = () => {
    let updatedKeys = {};
    const combinedRequiredDocs = modifiedRequiredDocs
    ? Object.assign(requiredDocs, modifiedRequiredDocs) 
    : requiredDocs
    const combinedFlaggedDocs = modifiedFlaggedDocs
      ? Object.assign(flaggedDocs, modifiedFlaggedDocs)
      : flaggedDocs
    for (const doc in combinedRequiredDocs) {
      updatedKeys[doc] = {};
      updatedKeys[doc].isRequired = combinedRequiredDocs[doc];
      updatedKeys[doc].hasConditions = combinedFlaggedDocs[doc];
    }
    return updatedKeys;
  };

  const handleSave = async () => {
    var combinedData = Object.assign(localLoc,modifiedLoc)
    if (combinedData) {
      setIsApproveModelOpen(false);
      const JSONStringAddress = combinedData.applicantAddress
        ?
        handleTextAreaFormatting(combinedData.applicantAddress)
        : "";

      const JSONStringDescriptionOfGoods = combinedData.descriptionOfGoods
      ?
      handleTextAreaFormatting(combinedData.descriptionOfGoods)
      : "";

      const updatedLoc = {
        ...combinedData,
        applicantAddress: JSONStringAddress,
        descriptionOfGoods: JSONStringDescriptionOfGoods,
        presentationDocuments: updateRequiredDocKeys(),
      };

      const response = await locAPI.updateLoc(combinedData.locID, updatedLoc);
      if(response.status === 200){
        dispatch(
          setSnackBarDisplay(
            SHOW_SNACKBAR, 
            {
              status: "success",
              message: "Letter of Credit updated"
            }
          )
        );
        setModifiedLoc({})
        setModifiedRequiredDocs(null)
        setModifiedFlaggedDocs(null)
        setTouched(false);
      }
    }
    onSaveLoc();
  };

  const handleCloseDetailsPanel = () => {
    touched
      ?
      setIsApproveModelOpen(true)
      :
      history.push("/loc");
  };

  const handleModalClose = () => {
    setIsApproveModelOpen(false);
  };

  const restrictedAccessOnFunctions = (func, action) => {
    return RestrictedAccess.DetailsPanel[action].includes(userGroup)
      ?
      () => {
        return;
      }
      : func;
  };

  const restrictedAccessOnComponents = (action) => {
    return !RestrictedAccess.DetailsPanel[action].includes(userGroup);
  };

  const onHandleDocumentChanges = (event) => {
    const { target: { name, checked } } = event;
    setModifiedRequiredDocs({
      ...modifiedRequiredDocs,
      [name]: checked,
    })
    setTouched(true);
  };
 
  const onHandleFlagDocChanges = (doc) => {
    setModifiedFlaggedDocs({
      ...modifiedFlaggedDocs,
      [doc]: !flaggedDocs[doc]
    })
    setTouched(true);
  }

  if (!loc) {
    return null;
  }
  const dateFieldCollection = [
    {label: "Date of Issue", propName: "issueDate"},
    {label: "Expiration Date", propName: "expiryDate"},
    {label: "Latest Ship Date", propName: "latestShipmentDate"}
  ];
  const applicantFieldCollection = [
    {label: "Applicant", propName: "applicant", column: 2},
    {label: "Applicant Address", propName: "applicantAddress", column: 3},
    {label: "Issuing Bank", propName: "issuingBank", column: 2}
  ];
  const advisingFieldCollection = [
    {label: "Advising Bank", propName: "beneficiaryBank"},
    {label: "Advising Bank #", propName: "beneficiaryBankReference"}
  ]
  const portFieldCollection = [
    {label: "Port of Loading", propName: "portOfLoading", column: 3},
    {label: "Port of Discharge", propName: "portOfDischarge", column: 3}
  ];

  const modalContent = (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div> 
            Do you want to save your 
            changes before exiting this page?
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.grayText}> 
            Note:Progress made in the Workflow 
            is always saved automatically.
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    loc &&
    loc !== null && (
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <div className={classes.pageHeader} >
            <div>
              Letter of Credit #<strong>{loc.locID ? loc.locID : ""}</strong>
            </div>
            <div className={classes.closeButton} onClick={handleCloseDetailsPanel}>
              <img className={classes.closeIcon} src={closeIcon} alt={"closeIcon"} />
            </div>
            {(touched && isApproveModelOpen) && (
              <NewApproveModal
                isOpen={isApproveModelOpen}
                width={350}
                handleClose={handleModalClose}
                headerText={"You have unsaved changes"}
                approveButtonText={"Save & Exit"}
                rejectButtonText={"Exit w/o Saving"}
                handleApprove={async () => {
                  await handleSave(); 
                  history.push("/loc");
                }}
                handleReject={() => history.push("/loc")}
                content={modalContent}
              />
            )}
          </div>
          <div className={classes.detailsHeader} >
              Details
          </div>
          <Grid container justify="flex-start" alignItems="center" spacing={0} className={classes.inputRow}>
            <Grid item xs={10} xl={7}>
              <Grid container justify="flex-start" alignItems="center" spacing={0}>
                {
                  dateFieldCollection.map((dateField, idx) => {
                    return(
                      <Grid item xs={3} key={idx}>
                        <Typography className={classes.inputRowLabel}>
                          {dateField.label}
                        </Typography>
                        <ThemeProvider theme={datepickerTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="normal"
                              id={dateField.propName}
                              name={dateField.propName}
                              value={modifiedLoc[dateField.propName] 
                                ? modifiedLoc[dateField.propName] 
                                : localLoc[dateField.propName] 
                                  ? new Date(localLoc[dateField.propName] ) : null}
                              onChange={(date) => handleDateChange(date, dateField.propName)}
                              className={classes.datePicker}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                              disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </Grid>
                    );
                  })
                }
                <Grid item xs={3}>
                  <Typography className={clsx(classes.inputRowLabel)}>
                    {"Presentation Period"}
                  </Typography>
                  <Grid container  justify="space-between" alignItems="center" spacing={2} >
                    <Grid item xs={3}>
                      <input
                        type={"number"}
                        className={classes.inputBox}
                        style={{width: "90%"}}
                        defaultValue={loc.presentationPeriodDays}
                        name={"presentationPeriodDays"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <select
                        className={classes.select}
                        value={modifiedLoc.presentationPeriodType ?
                          modifiedLoc.presentationPeriodType:
                          localLoc.presentationPeriodType
                            ? localLoc.presentationPeriodType
                            : "default" }
                        name={"presentationPeriodType"}
                        onChange={e=>{handleBlur(e); setTouched(true)}}
                        disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                      >
                        <option value="default" className={classes.mutedText}>please select</option>
                        <option value="calendarDays">calendar days</option>
                        <option value="businessDays">business days</option>
                      </select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={0} className={classes.inputRow}>
            {applicantFieldCollection.map((applicantField, idx) => {
              return(
                <Grid item xs={applicantField.column} key={idx}>
                  <Typography className={classes.inputRowLabel}>
                    {applicantField.label}
                  </Typography>
                  {
                    applicantField.propName === "applicantAddress"
                      ?
                      <textarea
                        className={classes.textArea}
                        defaultValue={localLoc[applicantField.propName] && formatEscapedChars(localLoc[applicantField.propName])}
                        name={applicantField.propName}
                        type="string"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                      />
                      :
                      <input
                        className={classes.inputBox}
                        defaultValue={localLoc[applicantField.propName]}
                        name={applicantField.propName}
                        type="string"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                      />
                  }
                </Grid>
              ); 
            })
            }
            {
              advisingFieldCollection.map((advisingField, idx) => {
                return(
                  <Grid item xs={2} key={idx}>
                    <Typography className={classes.inputRowLabel}>
                      {advisingField.label}
                    </Typography>
                    <input
                      className={classes.inputBox}
                      defaultValue={localLoc[advisingField.propName]}
                      name={advisingField.propName}
                      type="string"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                    />
                  </Grid>
                );
              })
            }
          </Grid>
          <Grid container  justify="flex-start" alignItems="center" spacing={0} className={classes.inputRow}>
            <Grid item xs={8} xl={6}>
              <Typography className={classes.inputRowLabel}>
                Description of Goods
              </Typography>
              <Grid container  justify="flex-start" alignItems="center" spacing={0} >
                <Grid item xs={12}>
                <div className={classes.flexContainer}>
                  <input
                    type={"string"}
                    className={classes.inputBox}
                    style={{width: "18%"}}
                    defaultValue={loc.productWeight}
                    name={"productWeight"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                  />
                  <Typography style={{width: "15%"}} className={classes.textOffset}>
                    metric tons of
                  </Typography>
                  <input
                    className={classes.inputBox}
                    style={{width: "45%"}}
                    defaultValue={localLoc.descriptionOfGoods && formatEscapedChars(localLoc.descriptionOfGoods)}
                    name={"descriptionOfGoods"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                  />
                  <Typography className={classes.textOffset} style={{width: "5%"}}>+/-</Typography>
                  <input
                      type={"number"}
                      className={classes.inputBox}
                      style={{width: "7%"}}
                      defaultValue={loc.productWeightTolerance}
                      name={"productWeightTolerance"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                    />
                    <Typography className={classes.textOffset} style={{width: "2%"}}>%</Typography>
                </div>
                </Grid>
               
              </Grid>
            </Grid>
            <Grid item xs={4} xl={3}>
              <Grid container  justify="flex-start" alignItems="center" spacing={0} >
                <Grid item xs={12}>
                  <div className={classes.flexContainer}>
                    <div style={{width: "45%"}}>
                    <Typography className={clsx(classes.inputRowLabel,classes.lcLabelOffset)}>
                      LC Amount<span className={classes.mutedText}> (USD)</span>
                    </Typography>
                    </div>
                    <div style={{width:"55%"}}>
                    <Typography className={clsx(classes.inputRowLabel,classes.paymentLabelOffset)}>
                      Payment Amount<span className={classes.mutedText}> (USD)</span>
                    </Typography>
                    </div>
                  </div>
                  <Grid container  justify="flex-start" alignItems="center" spacing={0} >
                    <Grid item xs={12}>
                      <div className={classes.paymentFlexContiner} style={{width: "100%"}}>
                        <div className={classes.paymentFlexContiner} style={{width: "45%"}}>
                          <Typography className={classes.textOffset} style={{marginRight: 5}}>
                            $
                          </Typography>
                          <input
                            type={"string"}
                            className={classes.inputBox}
                            style={{width: "70%"}}
                            defaultValue={loc.locAmount}
                            name={"locAmount"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                          />
                        </div>
                        <div className={classes.paymentFlexContiner} style={{width: "45%"}}>
                          <Typography className={classes.textOffset} style={{marginRight: 5}}>
                            $
                          </Typography>
                          <input
                            type={"string"}
                            className={classes.inputBox}
                            style={{width: "70%"}}
                            defaultValue={loc.paymentAmount}
                            name={"paymentAmount"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container  justify="flex-start" alignItems="center" spacing={0} className={classes.inputRow}>
            {
              portFieldCollection.map((portField,idx) => {
                return(
                  <Grid item xs={portField.column + 1} xl={portField.column} key={idx}>
                    <Typography className={classes.inputRowLabel}>
                      {portField.label}
                    </Typography>
                    <input
                      className={classes.inputBox}
                      style={{width: "85%"}}
                      defaultValue={localLoc[portField.propName]}
                      name={portField.propName}
                      type="string"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                    />
                  </Grid>
                );
              })
            }
            <Grid item xs={2} >
              <Typography className={classes.inputRowLabel}>
                {"Vessel Name"}
              </Typography>
              <input
                className={classes.inputBox}
                style={{width: "80%"}}
                defaultValue={localLoc["vesselName"]}
                name={"vesselName"}
                type="string"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
              />
            </Grid>
          </Grid>
          <Grid container  justify="flex-start" alignItems="center" spacing={0} className={classes.inputRow}>
            <Grid item xs={6} sm={4} md={3} lg={2}>
                <Typography className={classes.inputRowLabel}>
                  Bill of Lading Date
                </Typography>
                <ThemeProvider theme={datepickerTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="billOfLadingDate"
                      name={"billOfLadingDate"}
                      value={
                        modifiedLoc.billOfLadingDate 
                        ? modifiedLoc.billOfLadingDate 
                        : localLoc.billOfLadingDate 
                          ? new Date(localLoc.billOfLadingDate) : null}
                      onChange={(date) => handleDateChange(date,"billOfLadingDate")}
                      className={classes.datePicker}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography className={classes.inputRowLabel}>
                  {"Bill of Lading Weight"}<span className={classes.mutedText}> (metric tons)</span>
                </Typography>
                <input
                  className={classes.inputBox}
                  style={{width: 140}}
                  defaultValue={localLoc["billOfLadingWeight"]}
                  name={"billOfLadingWeight"}
                  type="string"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isLocStatusOpen && loc.status !== "presentationRejected"}
                />
              </Grid>
            </Grid>
          <RequiredDocs
            handleReqDocumentChanges={onHandleDocumentChanges}
            handleFlagDocumentChanges={onHandleFlagDocChanges}
            requiredDocs={requiredDocs}
            modifiedRequiredDocs={modifiedRequiredDocs}
            flaggedDocs={flaggedDocs}
            modifiedFlaggedDocs={modifiedFlaggedDocs}
          />
        </div>
        {loc && (
          <>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} className={classes.actionBar}>
                <div className={classes.actionButtonContainer}>
                  {
                    (restrictedAccessOnComponents("save") && (isLocStatusOpen || loc.status === "presentationRejected"))  && (
                      <Button 
                        disabled={!touched}
                        className={classes.save} 
                        variant="contained" 
                        onClick={restrictedAccessOnFunctions(handleSave, "save")}
                      >
                        Save
                      </Button>
                    )
                  }
                  <Button className={classes.eventsLogBtn} onClick={handleViewEventsLogClick}>
                    View Activity Log
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        )
        }
      </div>
    )
  );
};

export default withRouter(DetailsPanel);

DetailsPanel.propTypes = {
  history: PropTypes.object,
  handleViewEventsLogClick: PropTypes.func,
  isLocStatusOpen: PropTypes.bool,
  userGroup: PropTypes.string,
  onSaveLoc: PropTypes.func,
};