import React from "react";
import PropTypes from "prop-types";

import { Grid, IconButton, Modal, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { ModalStyle } from "./style";

export default function SimpleModal({
  content,
  isOpen,
  handleClose,
  headerText,
  width,
}) {
  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      display: "flex",
      top: `${top}%`,
      left: `${left}%`,
      width: width,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };
  

  const { closeIcon, modalBody, modalHeader, paper } = ModalStyle();
  const [modalStyles] = React.useState(getModalStyle);
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Grid container style={modalStyles} className={paper}>
        <Grid item xs={12} className={modalHeader}>
          <Typography style={{fontWeight: "bold"}}>{headerText}</Typography>
          <IconButton onClick={handleClose}>
            <Clear className={closeIcon} />
          </IconButton>
        </Grid>
        <Grid 
          container 
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={modalBody}>
            {content}
        </Grid>
      </Grid>
    </Modal>
  );
}

SimpleModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
  history: PropTypes.object,
  content: PropTypes.any,
  headerText: PropTypes.any,
};
