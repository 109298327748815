import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { useHomePageStyles } from "./styles";

export default function ActiveLettersOfCredit({ activeLocs, history }) {
  const classes = useHomePageStyles();

  const LOC_ID = "locID";
  const EXPIRY_DATE = "expiryDate";
  const LATEST_SHIPMENT_DATE = "latestShipmentDate";
  const NEXT_STEP = "nextStep";
  const OPEN = "open";
  const PENDING_PRESENTATION_REVIEW = "pendingPresentationReview";
  const PRESENTATION_APPROVED = "presentationApproved";
  const PRESENTATION_REJECTED = "presentationRejected";
  const COMPLETED = "completed";
  const PRESENTATION_PERIOD = "presentationPeriodDays";
  const APPLICANT = "applicant";
  
  const handleLocDetails = (id) => {
    history.push({
      pathname: `/loc/${id}`,
    });
  };

  const formatDate = (date, latestShip, dueDate) => {
    if (date === "" || date === null) {
      return ""; 
    }
    const localDate = new Date(date).toLocaleDateString();
    const daysDiff = getDaysDiff(latestShip, dueDate);
    return (
      <>
        {localDate} <span className={classes.daysDiff}>{daysDiff}</span>
      </>
    );
  };

  const getDaysDiff = (startDate, endDate) => {
    if (startDate === "" || startDate === null || endDate === "" || endDate === null) {
      return "";
    }

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const todayDate = new Date();
    const lastDate = new Date(endDate);
    const output = Math.floor((lastDate - todayDate) / oneDay);

    return `(${output < 0 ? 0 : output} days left)`;
  };

  const displayNextStep = (status) => {
    switch (status) {
    case OPEN:
      return "Upload Presentation Documents";
    case PENDING_PRESENTATION_REVIEW:
      return "Awaiting Presentation Review";
    case PRESENTATION_APPROVED:
      return "Awaiting Payment";
    case PRESENTATION_REJECTED:
      return "Awaiting Presentation Review";
    case COMPLETED:
      return "Payment Received";
    default:
      return;
    }
  };

  const columns = [
    { id: "locID", label: "Letter of Credit ID" },
    { id: "applicant", label: "Applicant" },
    { id: "beneficiaryBank", label: "Advising Bank" },
    { id: "vesselName", label: "Vessel Name" },
    { id: "latestShipmentDate", label: "Latest Ship Date"},
    { id: "presentationPeriodDays", label: "Presentation Period"},
    { id: "expiryDate", label: "Expiration Date" },
    { id: "nextStep", label: "Next Step" },
  ];

  const formatPresentationType = (str) => {
    if (str === null) return "";
    const wordSplitIdx = str.indexOf("D");
    const formatted = str.substring(0, wordSplitIdx) + " " + str.substring(wordSplitIdx).toLowerCase();
    return formatted;
  };

  return (
    <div className={classes.root}>
      <div>
        <Table stickyHeader aria-label="sticky table" className={classes.locTable}>
          <TableHead>
            <TableRow style={{height: "50px"}}>
              {columns.map((column) => (
                <TableCell
                  className={classes.tableTitle}
                  key={column.id}
                  align={column.align}
                  style={{
                    width: column.id === APPLICANT ? "250px" : column.id === PRESENTATION_PERIOD ? "175px" : null
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {activeLocs && activeLocs.length
              ?
              activeLocs.map((loc, idx) => {
                const { Record } = loc;
                return (
                  <TableRow
                    key={Record.locID || idx}
                    role="checkbox"
                    tabIndex={-1}
                    className={classes.contentRow}
                  >
                    {
                      columns.map((column,index) => {
                        return (
                          <TableCell key={index} className={classes.tableCell}>
                            <span
                              onClick={() => column.id === LOC_ID && handleLocDetails(Record.locID)}
                              style={{
                                cursor: column.id === LOC_ID && "pointer",
                                color: column.id === LOC_ID && "#52B1B5",
                                width: column.id === APPLICANT && "250px",
                              }}
                            >
                              {
                                // this complexity is for handling date formats and days left.
                                // this has become a solid case for why over-engineering a component, or table, to be 'more re-usable' is sometimes not the best approach...
                                // great video diving into this is "React is Fiction" from 2019 React Conf.
                                column.id === EXPIRY_DATE || column.id === LATEST_SHIPMENT_DATE
                                  ?
                                  formatDate(Record[column.id], Record.latestShipmentDate, Record.expiryDate)
                                  :
                                  column.id === NEXT_STEP
                                    ? displayNextStep(Record.status)
                                    :
                                    column.id === PRESENTATION_PERIOD && Record[column.id] !== null
                                      ?
                                      `${Record[column.id]} ${formatPresentationType(Record["presentationPeriodType"])}`
                                      :
                                      Record[column.id]
                                        ? Record[column.id]
                                        : ""
                              }
                            </span>
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })
              :
              activeLocs === null
                ?
                <TableRow />
                :
                <TableRow>
                  <TableCell className={classes.loadingRow}>
                    Loading...
                  </TableCell>
                </TableRow>}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

ActiveLettersOfCredit.propTypes = {
  activeLocs: PropTypes.array,
  history: PropTypes.object,
};