import { makeStyles } from "@material-ui/styles";

export const useHomePageStyles = makeStyles({
  root: {
    padding: "1.5rem 4rem",
    // overflow: "scroll",
  },
  title: {
    fontWeight: 400,
  },
  createLetterButtonRoot: {
    display: "flex",
    justifyContent: "space-around",
    height: "3rem",
    width: 215,
    backgroundColor: "#52B1B5",
    color: "white",
    textTransform: "none",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#52B1B5",
      opacity: 0.8,
    }
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
});
