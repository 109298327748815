import {
  PRESENTATION_DOCUMENT_CREATE_ERROR,
  NEW_PRESENTATION_DOCUMENT_CREATED,
  ADD_PRESENTATION_DOCUMENT,
  PRESENTATION_DOCUMENT_APPROVED,
  PRESENTATION_DOCUMENT_REJECTED
} from "store/constants";
  
const initialState = {
  isFetching: false,
  transactionId: null,
  createError: null
};
  
export const presentationDocumentReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch(type) {
  case ADD_PRESENTATION_DOCUMENT:
    return {
      ...state,
      createdAt: Date.now(),
      isFetching: true,
	    transactionId: null
    };
  case NEW_PRESENTATION_DOCUMENT_CREATED:
    return {
      ...state,
      isFetching: false,
      transactionId: payload.transactionId
    };
  case PRESENTATION_DOCUMENT_CREATE_ERROR:
    return {
      ...state,
      isFetching: false,
      createError: payload.errorMessage
    };
  case PRESENTATION_DOCUMENT_APPROVED:
    return {
      ...state,
      transactionId: payload.transactionId
    };
  case PRESENTATION_DOCUMENT_REJECTED:
    return {
      ...state,
      transactionId: payload.transactionId
    };
  default:
    return state;
  }
};
  