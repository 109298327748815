import {
    ATTEMPT_LOGIN,
    LOGIN_SUCCESSFUL,
    LOGOUT
  } from "store/constants";
  
  const initialState = {
    identityToken: null,
    isFetching: false,
    firstName: null,
    lastName: null,
    email: null,
    userGroup: null,
    userName: null
  };
  
  export const authReducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch(type) {
    case ATTEMPT_LOGIN:
      return {
        ...state,
        isFetching: true
      };
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        isFetching: false,
        identityToken: payload.data.identityToken,
        firstName: payload.data.given_name,
        lastName: payload.data.family_name,
        email: payload.data.email,
        userGroup: payload.data.userGroup,
        userName: payload.data.userName
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
    }
  };
  