import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import SnackBarStyle from "./style";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
  const classes = SnackBarStyle();
  const {message, status, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], classes.content)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        status!=="success"?
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
         <CloseIcon className={classes.icon} />
        </IconButton>
        :""
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  status: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

export default function MessageSnackBar({
    open,
    status,
    message,
    handleClose
}) {
  return (
    status?
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={status === "success" ? 3000 : null}
        onClose={(event,reason)=>handleClose(event,reason)}
      >
        <MySnackbarContentWrapper
          onClose={(event,reason)=>handleClose(event,reason)}
          variant={status}
          message={message}
          status={status}
        />
      </Snackbar>
    </div>
    :""
  );
}