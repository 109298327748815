import {
  ATTEMPT_LOGIN,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  SHOW_SNACKBAR,
} from "store/constants";

import {
  attemptSignin,
  decodeIdentityToken,
  getIdentityToken,
  getUserAttributes,
  setAWSCredentials,
} from "auth";

import { setSnackBarDisplay } from "store/actions/displayActions";

export const ACTION_ATTEMPT_SIGNIN = () => ({
  type: ATTEMPT_LOGIN
});

export const ACTION_LOGIN_SUCCESSFUL = (data) => ({
  type: LOGIN_SUCCESSFUL,
  payload: { data },
});

export const ACTION_LOGOUT = () => ({
  type: LOGOUT,
  payload: {}
});

export const signin = (username, password) => {
  return async(dispatch) => {
    dispatch(ACTION_ATTEMPT_SIGNIN());

    return await attemptSignin(username, password)
      .then(async (response) => {
        const { data } = response; // contains the identityToken

        let identityTokenDecoded = decodeIdentityToken(data.identityToken);
        data["userGroup"] = identityTokenDecoded["cognito:groups"][0];
        data["userName"] = identityTokenDecoded["cognito:username"];

        const userAttributes = await getUserAttributes(); //gets name and email

        dispatch(ACTION_LOGIN_SUCCESSFUL(Object.assign(data, userAttributes)));
      }).catch(err => {
        dispatch(
          setSnackBarDisplay(
            SHOW_SNACKBAR, 
            {
              status: "error",
              message: err
            }
          )
        );
      });
  };
};

export const refreshUserFromSession = () => {
  return async(dispatch) => {
    let identityToken = await getIdentityToken();
    let identityTokenDecoded = decodeIdentityToken(identityToken);
    
    let data = {};
    data["identityToken"] = identityToken;
    data["userGroup"] = identityTokenDecoded["cognito:groups"][0];
    data["userName"] = identityTokenDecoded["cognito:username"];

    await setAWSCredentials();
    const userAttributes = await getUserAttributes(); //gets name and email

    dispatch(ACTION_LOGIN_SUCCESSFUL(Object.assign(data, userAttributes)));
  };
};

export const logoutUser = () => {
  return async(dispatch) => {
    dispatch(ACTION_LOGOUT());
  };
};