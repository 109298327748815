import React from "react";
import { Provider } from "react-redux";
import AppContainer from "App/AppContainer"

import { store } from "store";

export default function App() {
  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}
