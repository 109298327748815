export const ADD_NEW_LOC = "ADD_NEW_LOC";
export const FETCH_LOCS = "FETCH_LOCS";
export const RECEIVE_LOCS = "RECEIVE_LOCS";
export const REQUEST_LOCS = "REQUEST_LOCS";
export const REQUEST_LOC_DETAILS = "REQUEST_LOC_DETAILS";
export const RECEIVE_LOC_DETAILS = "RECEIVE_LOC_DETAILS";
export const NEW_LOC_CREATED = "NEW_LOC_CREATED";
export const LOC_CREATE_ERROR = "LOC_CREATE_ERROR";
export const ATTEMPT_LOGIN = "ATTEMPT_LOGIN";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGOUT = "LOGOUT";
export const ADD_PRESENTATION_DOCUMENT = "ADD_PRESENTATION_DOCUMENT";
export const NEW_PRESENTATION_DOCUMENT_CREATED = "NEW_PRESENTATION_DOCUMENT_CREATED";
export const PRESENTATION_DOCUMENT_CREATE_ERROR = "PRESENTATION_DOCUMENT_CREATE_ERROR";
export const UPLOAD_AMENDMENT_DOCUMENT = "UPLOAD_AMENDMENT_DOCUMENT";
export const AMENDMENT_DOCUMENT_CREATED = "AMENDMENT_DOCUMENT_CREATED";
export const AMENDMENT_DOCUMENT_CREATE_ERROR = "AMENDMENT_DOCUMENT_CREATE_ERROR";
export const PRESENTATION_DOCUMENT_APPROVED = "PRESENTATION_DOCUMENT_APPROVED";
export const PRESENTATION_DOCUMENT_APPROVE_ERROR = "PRESENTATION_DOCUMENT_APPROVE_ERROR";
export const PRESENTATION_DOCUMENT_REJECTED = "PRESENTATION_DOCUMENT_REJECTED";
export const PRESENTATION_DOCUMENT_REJECT_ERROR = "PRESENTATION_DOCUMENT_REJECT_ERROR";
export const PRESENTATION_PREPARED = "PRESENTATION_PREPARED";
export const PRESENTATION_APPROVED = "PRESENTATION_APPROVED";
export const PRESENTATION_REJECTED = "PRESENTATION_REJECTED";
export const PRESENTATION_CLOSED = "PRESENTATION_CLOSED";
export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const CLEAR_LOC = "CLEAR_LOC";
