import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  FormControl,
  Grid,
} from "@material-ui/core";

import { signin } from "store/actions";
import { useSigninPageStyles, BootstrapInput } from "./styles";

import { userIsLoggedIn } from "auth";

export default function Signin({ history }) {
  const classes = useSigninPageStyles();

  const dispatch = useDispatch();
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);

  const { identityToken } = useSelector((state) => state.authReducer);

  React.useEffect( () => {
    if (userIsLoggedIn() || identityToken) {
      history.push({
        pathname: "/loc",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityToken]);

  const handleSubmit = () => {
    dispatch(signin(username, password));
  };

  const handleKeyDown = (e) => {
    let { keyCode } = e;
    if (keyCode === 13) {
      dispatch(signin(username, password));
    }
  };

  const contura = "https://contura-web-assets.s3.amazonaws.com/images/contura.png";
  const logo = "https://contura-web-assets.s3.amazonaws.com/images/logo.png";

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.headingContainer}>
          <div className={classes.heading}>
            <img src={logo} className={classes.logo} alt={"logo"} />
            <img src={contura} className={classes.contura} alt={"contura"} />
          </div>
          <div>
            <h1 className={classes.header}>Letter of Credit Portal</h1>
            <p className={classes.subText}>Built by AWS. Powered by Blockchain.</p>
          </div>
        </div>
        <div className={classes.inputContainer}>
          <Grid 
            container 
            direction="column"
            justify="center"
            alignItems="center"
            >
            <Grid item xs={12}>
              <FormControl>
                <BootstrapInput
                  className={classes.inputBox}
                  placeholder={"Username"}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <BootstrapInput
                  type={"password"}
                  placeholder={"Password"}
                  className={classes.inputBox}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.signin}
                  onClick={handleSubmit}
                >
                  Sign In
                </Button>
                </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

Signin.propTypes = {
  history: PropTypes.object,
};