import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import { useDropZoneStyles } from "./styles";

export default function MyDropZone({
  uploadDoc,
  children,
}) {
  const classes = useDropZoneStyles();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
  });
  
  const files = acceptedFiles.map((file) => (
    `${file.path} - ${file.size} bytes`
  ));

  React.useEffect(() => {
    if (acceptedFiles.length) {
      uploadDoc(acceptedFiles[0]);
    }
  }, [acceptedFiles, uploadDoc]);

  return (
    <section className="container" style={{width: "100%"}}>
      <div
        {...getRootProps({className: `${classes.root} dropzone`})}
      >
        <input {...getInputProps()} />
        <div className={classes.files}>
          {!files.length
            ?
            children
            :
            <p>{files}</p>
          }
        </div>
      </div>
    </section>
  );
}

MyDropZone.propTypes = {
  uploadDoc: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};