import {makeStyles } from "@material-ui/styles";
import { amber } from '@material-ui/core/colors';

const SnackBarStyle = makeStyles(theme => ({
    success: {
      backgroundColor: "#52B1B5",
    },
    error: {
      backgroundColor: "#FF5252",
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    content: {
      marginTop: 30,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
}));

export default SnackBarStyle