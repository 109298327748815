import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { buildRootReducer } from "store/reducers";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();
const rootReducer = buildRootReducer(browserHistory);
const thunk = thunkMiddleware;

const middlewareEnhancer = applyMiddleware(
  thunk,
  routerMiddleware(browserHistory),
);

const composedEnhancers = composeWithDevTools(middlewareEnhancer);
const store = createStore(rootReducer, composedEnhancers);

export { store, browserHistory };