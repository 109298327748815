import { makeStyles, withStyles } from "@material-ui/styles";
import { InputBase } from "@material-ui/core";

export const useViewDocStyles = makeStyles({
  root: {
    overflow: "auto",
    minHeight: "100%",
  },
  goBack: {
    display: "flex",
    backgroundColor: "#F2F3F4",
    border: "none",
    marginLeft: "3rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    fontSize: 21,
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrowIconContainer: {
    display: "inline-flex",
    marginTop: "auto",
    marginBottom: "auto",
  },
  arrowIcon: {
    height: 20,
    width: 20,
  },
  docNavigation: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "80%",
    margin: "1rem auto",
    paddingBottom: "1.5rem",
    // borderBottom: "1px solid lightgray",
  },
  pagesInput: {
    width: "20px",
    height: "20px",
    padding: "0 5px",
    margin: "0 .5rem",
  },
  navIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabledNavIcon: {
    color: "#C2C5CA",
  },
  docInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 2rem",
    "& div": {
      minHeight: "70px",
    },
    "& div > h4": {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19px",
      color: "#232F3E",
      textTransform: "uppercase",
      marginBottom: "0.75rem",
    },
  },
  amendmentButton: {
    width: "165px",
    height: "38px",
    color: "#FFF",
    borderRadius: "5px",
    outline: "none",
    fontWeight: "bold",
    backgroundColor: "#52B1B5",
    margin: "0 auto",
    marginLeft: "2rem",
    marginTop: "3rem",
    "&:hover": {
      backgroundColor: "#52B1B5",
      cursor: "pointer",
    },
    "&:focus": {
      outline: "none",
    },
  },
  pdfIcon: {
    width: 60,
    height: 75,
  },
  headerActions: {
    display: "flex",
    justifyContent: "flexStart",
    alignItems: "center",
  },
  formMargin: {
    marginLeft: "15%",
  },
  pdfWrapper: {
    minHeight: "542px",
    maxHeight: "calc(100vh - 404px)",
    overflow: "scroll",
  },
  contentContainer: {
    minHeight: "605px !important",
    borderBottom: "1px solid lightgray",
  },
  textInDropZone: {
    margin: 0,
    marginTop: 5, 
    size: 11,
    fontWeight: 400,
    "&:hover": {
      cursor: "default",
    },
  },
  greenText: {
    color: "#52B1B5",
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
    },
  },
  modalContent: {
    display: "inline-block !important",
    textAlign: "center !important",
    color: "#A7A1B3",
    "&:hover": {
      cursor: "default",
      outlineColor: "#5AB4B8",
    },
    "&:active": {
      outlineColor: "#5AB4B8",
    },
  }
});

export const BootstrapInput = withStyles(() => ({
  root: {
    "label + &": {
      marginTop: 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFF",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
