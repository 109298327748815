import { makeStyles } from "@material-ui/styles";

export const useAppContainerStyles = makeStyles({
  root: {
    width: "auto",
    margin: "0px",
    color: "#232F3E",
    fontFamily: "\"Amazon Ember\",sans-serif",
    backgroundColor: "#F2F3F4",
    height: "100vh",
    overflow: "auto"
  }
});
