import React from "react";
import PropTypes from "prop-types";
import AWS from "aws-sdk";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { FormControl, NativeSelect } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import Loader from "react-loader-spinner";

import { useViewDocStyles, BootstrapInput } from "./styles";
import { config } from "config";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewDoc({
  docType,
  approveDocument,
  rejectDocument,
  handleCloseDoc,
  isLocStatusOpen,
}) {
  const { loc } = useSelector((state) => state.locDetailsReducer);
  const { userGroup } = useSelector((state) => state.authReducer);
  const initialDocVersion = loc.presentationDocuments[docType].versions.length - 1;
  const currentDocument = loc.presentationDocuments[docType];

  const classes = useViewDocStyles();
  const [pageNum, setPageNum] = React.useState(1);
  const [numOfPages, setNumOfPages] = React.useState(null);
  const [s3SignedURL, setS3SignedURL] = React.useState(null);
  const [docVersion, setDocVersion] = React.useState(initialDocVersion);
  const [isDocApproved, setIsDocApproved] = React.useState(null);

  const PREV = "Prev";
  const NEXT = "Next";
  const APPROVED = "approved";
  const CONTURA_OPERATIONS = "ConturaOperations";

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  React.useEffect(() => {
    setPageNum(1);
  }, [docVersion]);

  React.useEffect(() => {
    const getSignedURL = async () => {
      const documentBucket = config.documentBucket;
      const bucketURL = "https://" + documentBucket + ".s3.amazonaws.com/";
      const s3Key = loc.presentationDocuments[docType].versions[docVersion]["contentURL"].slice(bucketURL.length);
      const s3 = new AWS.S3({apiVersion: "2006-03-01"});
      const params = {Bucket: documentBucket, Key: s3Key};
      const signedURL = await s3.getSignedUrlPromise("getObject", params);
      setS3SignedURL(signedURL);
    };

    if (loc) {
      getSignedURL();
    }
  }, [loc, docType, docVersion]);

  React.useEffect(() => {
    if (loc && loc.presentationDocuments[docType].status === APPROVED) {
      setIsDocApproved(true);
    }
  }, [loc, docType]);

  const handlePageChange = (direction) => {
    let page;
    if (direction === PREV) {
      page = Math.max(1, pageNum - 1);
    } else if (direction === NEXT) {
      page = Math.min(numOfPages, pageNum + 1);
    }
    setPageNum(page);
  };

  if (!loc) {
    return null;
  }

  const prettifyDocType = () => {
    if (docType === "certificateOfAnalysis") {
      return "Certificate of Analysis";
    } else if (docType === "billOfLading") {
      return "Bill of Lading";
    } else if (docType === "commercialInvoice") {
      return "Commercial Invoice";
    } else if (docType === "certificateOfWeight") {
      return "Certificate of Weight";
    } else if (docType === "certificateOfOrigin") {
      return "Certificate of Origin";
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString([], {hour: "numeric", minute: "2-digit"});
  };
 
  return (
    <div className={classes.root}>
      <div className={classes.headerActions}>
        <button className={classes.goBack} onClick={handleCloseDoc}>
          <span className={classes.arrowIconContainer}>
            <ArrowBackIosIcon className={classes.arrowIcon} />
          </span>
          Back
        </button>
        <FormControl className={classes.formMargin}> 
          <NativeSelect 
            id="documentVersionSelector" 
            value={docVersion} 
            onChange={(e) => setDocVersion(e.target.value)} 
            input={<BootstrapInput />} 
          > 
            {loc.presentationDocuments[docType].versions.length > 0 &&
              loc.presentationDocuments[docType].versions.map((ele, idx) => ( 
                <option key={idx} value={idx}>Version {idx + 1}</option> 
              ))} 
          </NativeSelect> 
        </FormControl> 
      </div>


      <div className={classes.contentContainer}>
        {s3SignedURL && (
          <>
            <div className={classes.pdfWrapper}>
              <Document
                file={s3SignedURL}
                loading={
                  <Loader
                    type={"Oval"}
                    color={"#52B1B5"}
                    height={50}
                    width={50}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50%",
                    }}
                  />
                }
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNum} />
              </Document>
            </div>
            <div className={classes.docNavigation}>
              {numOfPages && (
                <>
                  <ArrowLeftIcon
                    className={pageNum !== 1 ? classes.navIcon : classes.disabledNavIcon}
                    onClick={() => handlePageChange(PREV)}
                  />
                  <input
                    type={"number"}
                    className={classes.pagesInput}
                    value={pageNum}
                    readOnly
                  />
                  <span>of {numOfPages} pages</span>
                  <ArrowRightIcon
                    className={pageNum !== numOfPages ? classes.navIcon : classes.disabledNavIcon}
                    onClick={() => handlePageChange(NEXT)}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className={classes.docInfo}>
        <div>
          <h4>Document</h4>
          <span>{prettifyDocType()}</span>
        </div>
        <div>
          <h4>Uploader</h4>
          <span>{currentDocument && currentDocument.versions[0].createdBy}</span>
        </div>
        <div>
          <h4>Uploaded</h4>
          <span>{currentDocument && formatDate(currentDocument.versions[0].createdAt)}</span>
        </div>
      </div>
      {currentDocument.status === APPROVED && (
        <div className={classes.docInfo}>
          <div>
            <h4>Approved By</h4>
            <span>{currentDocument && currentDocument.approvedBy}</span>
          </div>
          <div>
            <h4>Approved At</h4>
            <span>{currentDocument && formatDate(currentDocument.approvedAt)}</span>
          </div>
        </div>
      )}
      {((isLocStatusOpen || loc.status === "presentationRejected") && !isDocApproved && userGroup === CONTURA_OPERATIONS) && (
        <div className={classes.buttonContainer}>
          <button className={classes.approveButton} onClick={() => approveDocument(docType)}>
            Approve
          </button>
          <button className={classes.rejectButton} onClick={() => rejectDocument(docType)}>
            Reject
          </button>
        </div>
      )}
    </div>
  );
}

ViewDoc.propTypes = {
  docType: PropTypes.string,
  approveDocument: PropTypes.func,
  rejectDocument: PropTypes.func,
  handleCloseDoc: PropTypes.func,
  isLocStatusOpen: PropTypes.bool,
};
