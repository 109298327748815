/* eslint-disable quotes */
import { makeStyles } from "@material-ui/styles";

export const NewApproveModalStyle = makeStyles({
  approveButton: {
    height: 35,
    width: "100%",
    fontSize: 12,
    backgroundColor: "#52B1B5",
    color: "white",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#52B1B5",
    }
  },
  rejectButton: {
    width: "100%",
    height: 35,
    fontSize: 12
  },
  contentContainer: {
    padding: 30,
    backgroundColor: "#EAEBED"
  },
  buttonsContainer: {
    padding:"15px 30px",
  }
});
