import {
    UPLOAD_AMENDMENT_DOCUMENT,
    AMENDMENT_DOCUMENT_CREATED,
    AMENDMENT_DOCUMENT_CREATE_ERROR
    } from "store/constants";
    
    const initialState = {
      isFetching: false,
      transactionId: null,
      createError: null
    };
    
    // eslint-disable-next-line no-unused-vars
    
    export const locAmendmentReducer = (state = initialState, action) => {
      const { payload, type } = action;
      switch(type) {
      case UPLOAD_AMENDMENT_DOCUMENT:
        return {
          ...state,
          createdAt: Date.now(),
          isFetching: true,
	        transactionId: null
        };
      case AMENDMENT_DOCUMENT_CREATED:
        return {
          ...state,
          isFetching: false,
          transactionId: payload.transactionId
        };
      case AMENDMENT_DOCUMENT_CREATE_ERROR:
        return {
          ...state,
          isFetching: false,
          createError: payload.errorMessage
        };
      default:
        return state;
      }
    };
    