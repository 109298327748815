import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F2F3F4",
    color: theme.palette.common.black,
    borderBottom: "2px solid black",
    fontWeight: 700
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

export default StyledTableCell;