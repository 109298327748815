import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import uploadPdf from "assets/uploadPdf.png";
import { useDropZoneStyles } from "./styles";

export default function MyDropZone({ uploadLoc }) {
  const classes = useDropZoneStyles();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
  });
  
  const files = acceptedFiles.map((file) => (
    `${file.path} - ${file.size} bytes`
  ));

  React.useEffect(() => {
    if (acceptedFiles.length) {
      uploadLoc(acceptedFiles[0]);
    }
  }, [acceptedFiles, uploadLoc]);

  return (
    <section className="container" style={{width: "100%"}}>
      <div
        {...getRootProps({className: `${classes.root} dropzone`})}
      >
        <input {...getInputProps()} />
        <div className={classes.files}>
          {!files.length
            ?
            <>
              <img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"}/>
              <p className={classes.textInDropZone}>Drop a scanned</p>
              <p className={classes.textInDropZone}>Letter of Credit here</p>
              <p className={classes.textInDropZone}>
                or <span className={classes.greenText}>Choose File</span>
              </p>
            </>
            :
            <>
              <img src={uploadPdf} className={classes.pdfIcon} alt={"pdfIcon"}/>
              <p>{files}</p>
            </>
          }
        </div>
      </div>
    </section>
  );
}

MyDropZone.propTypes = {
  uploadLoc: PropTypes.func.isRequired,
};