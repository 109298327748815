import {
  ADD_NEW_LOC,
  AMENDMENT_DOCUMENT_CREATED,
  AMENDMENT_DOCUMENT_CREATE_ERROR,
  CLEAR_LOC,
  LOC_CREATE_ERROR,
  NEW_LOC_CREATED,
  RECEIVE_LOCS,
  REQUEST_LOCS,
  RECEIVE_LOC_DETAILS,
  REQUEST_LOC_DETAILS,
  SHOW_SNACKBAR,
  UPLOAD_AMENDMENT_DOCUMENT,
} from "store/constants";

import { loc as locAPI } from "api";
import { apiErrorHandler } from "store/actions";
import { setSnackBarDisplay } from "store/actions/displayActions";

export const ACTION_ADD_LOC = () => ({
  type: ADD_NEW_LOC,
});

export const ACTION_LOC_CREATED = (transactionId) => ({
  type: NEW_LOC_CREATED,
  payload: { transactionId }
});

export const ACTION_LOC_CREATE_ERROR = (errorMessage) => ({
  type: LOC_CREATE_ERROR,
  payload: { errorMessage }
});

export const ACTION_UPLOAD_AMENDMENT_DOCUMENT = () => ({
  type: UPLOAD_AMENDMENT_DOCUMENT,
});

export const ACTION_AMENDMENT_DOCUMENT_CREATED = (transactionId) => ({
  type: AMENDMENT_DOCUMENT_CREATED,
  payload: { transactionId }
});

export const ACTION_AMENDMENT_DOCUMENT_CREATE_ERROR = (errorMessage) => ({
  type: AMENDMENT_DOCUMENT_CREATE_ERROR,
  payload: { errorMessage }
});

export const ACTION_CLEAR_LOC = () => ({
  type: CLEAR_LOC,
});

export const receiveAllLocs = (locs) => ({
  type: RECEIVE_LOCS,
  payload: { locs },
});

export const requestAllLocs = () => ({
  type: REQUEST_LOCS,
});

export const receiveLocDetails = (loc) => ({
  type: RECEIVE_LOC_DETAILS,
  payload: { loc },
});

export const requestLocDetails = () => ({
  type: REQUEST_LOC_DETAILS,
});

export const fetchAllLocs = () => {
  return async(dispatch) => {
    dispatch(requestAllLocs());

    return await locAPI.getLocs()
      .then((response) => {
        const { data } = response;
        dispatch(receiveAllLocs(data));
      }).catch(apiErrorHandler);
  };
};

export const fetchLocDetails = (locId) => {
  return async(dispatch) => {
    dispatch(requestLocDetails());

    return await locAPI.getLocDetails(locId)
      .then((response) => {
        const { data } = response;
        dispatch(receiveLocDetails(data));
      }).catch(apiErrorHandler);
  };
};

export const addLoc = (locID, file) => {
  return async(dispatch) => {
    dispatch(ACTION_ADD_LOC());

    return await locAPI.createLOC(file, locID)
      .then((response) => {
        const { data } = response;
        let createResult = JSON.parse(data.putResult.Payload);
        const transactionId = createResult.transactionId;
        if (createResult.errorMessage) {
          console.log("got error creating loc ", createResult.errorMessage);
          dispatch(ACTION_LOC_CREATE_ERROR(createResult.errorMessage));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "error",
                message: createResult.errorMessage
              }
            )
          );
        } else {  
          dispatch(ACTION_LOC_CREATED(transactionId));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "success",
                message: "Letter of Credit created"
              }
            )
          );
        }
      }).catch(apiErrorHandler);
  };
};

export const uploadAmendmentDocument = (locID, file) => {
  return async(dispatch) => {
    return await locAPI.uploadAmendment(locID, file)
      .then((response) => {
        const { data } = response;
        let createResult = JSON.parse(data.putResult.Payload);
        const transactionId = createResult.transactionId;
        if (createResult.errorMessage) {
          console.log("Error uploading amendment document: ", createResult.errorMessage);
          dispatch(ACTION_AMENDMENT_DOCUMENT_CREATE_ERROR(createResult.errorMessage));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "error",
                message: createResult.errorMessage
              }
            )
          );
        } else {
          dispatch(ACTION_AMENDMENT_DOCUMENT_CREATED(transactionId));
          dispatch(
            setSnackBarDisplay(
              SHOW_SNACKBAR, 
              {
                status: "success",
                message: "Amendment uploaded"
              }
            )
          );
        }
      }).catch(apiErrorHandler);
  };
};

export const clearLoc = () => {
  return (dispatch) => {
    dispatch(ACTION_CLEAR_LOC());
  };
};