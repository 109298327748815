import React from "react";
import PropTypes from "prop-types";
import { Grid,Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import SimpleModal from "components/modal";
import MyDropZone from "App/NewLocModal/DropZone";

import { useModalStyles } from "./styles";

export default function NewLocModal({
  handleClose,
  isOpen,
  width,
  submitNewLoc,
}) {

  const classes = useModalStyles();
  const [locFile, setLocFile] = React.useState(null);
  const [locID, setLocID] = React.useState(null);

  const handleSubmit = () => {
    if (locFile && locID) {
      submitNewLoc(locFile, locID);
    }
  };

  const modalContent = (
    <>
      <MyDropZone uploadLoc={setLocFile} />
      <div className={classes.inputSection}>
        <Grid item xs={12}>
          <Typography className={classes.label}>Letter of Credit ID</Typography>
        </Grid>
        <Grid item xs={12} className={classes.inputContainer}>
          <input className={classes.locIdInput} onChange={(e) => setLocID(e.target.value)} />
        </Grid>
      </div> 
      <Grid item xs={12}>
        <div className={classes.buttonContainer}>
          <Button className={classes.createButton} onClick={() => handleSubmit()}>Create Letter of Credit</Button>
        </div>
      </Grid>
    </>
  );

  return (
    isOpen && (
      <SimpleModal
        isOpen={isOpen}
        handleClose={handleClose}
        width={width}
        headerText={"Create a new Letter of Credit"}
        content={modalContent}
      /> 
    )
  );
}

NewLocModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
  submitNewLoc: PropTypes.func,
};

