import { makeStyles } from "@material-ui/styles";

export const tableStyle = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  pagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
