import { createStyles, makeStyles } from "@material-ui/styles";

export const useModalStyles = makeStyles(() =>
  createStyles({
    paper: {
      "position": "absolute",
      "backgroundColor": "#FFF",
      "boxShadow": 5,
      "padding": 0,
      "borderRadius": 5,
      "&:focus": {
        outlineWidth: 0
      }
    },
    modalHeader: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center",
      "borderBottom": "1px solid",
      "& p": {
        paddingLeft: ".5rem",
      },
      "& button": {
        padding: "6px",
      },
    },
    closeIcon: {
      fill: "#000",
    },
    modalBody: {
      width: "90%",
      backgroundColor: "#EAEBED",
      margin: "1rem auto",
      "& div": {
        display: "flex",
        justifyContent: "center",
      },
    },
    createButton: {
      marginTop: ".5rem",
      height: "1.75rem", 
      maxHeight: "1.75rem",
      width: "5rem",
      color: "#FFF",
      backgroundColor: "#2A78E4", //lightblue
      "&:focus": {
        outline: 0,
      },
    },
    inputSection: {
      display: "inline-block !important",
      margin: "0 auto !important",
      marginTop: "1rem !important",
    },
  })
);
