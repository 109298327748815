import { makeStyles } from "@material-ui/styles";

export const useCompletedLocStyles = makeStyles({
  root: {
    padding: "1.5rem 0 3rem 0",
    marginTop: "2rem",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontWeight: 400
  },
  tableTitle: {
    padding: "8px 15px",
    backgroundColor: "#F2F3F4",
    color: "#000",
    opacity: 1,
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: "bold",
    borderBottom: "2px solid #6F7680",
  },
  tableCell: {
    fontSize: "16px",
  },
});