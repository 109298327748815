import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import MyDropZone from "App/NewDocModal/DropZone";
import { useModalStyles } from "./styles";
import SimpleModal from "components/modal";

export default function NewDocModal({
  handleClose,
  handleSubmitDoc,
  isOpen,
  width,
  headerText,
  children,
}) {
  const {modalBody} = useModalStyles();

  const handleSubmit = (document) => {
    handleSubmitDoc(document);
  };

  const modalContent = (
    <Grid container className={modalBody}>
      <MyDropZone uploadDoc={handleSubmit}>
        {children}
      </MyDropZone>
    </Grid>
  );

  return (
    <SimpleModal
      isOpen={isOpen}
      handleClose={handleClose}
      width={width}
      headerText={headerText}
      content={modalContent}
    />
  );
}

NewDocModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmitDoc: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
  headerText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

