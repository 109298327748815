import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import { locCreateReducer } from "store/reducers/locCreate";
import { locsReducer } from "store/reducers/locs";
import { locDetailsReducer } from "store/reducers/locDetails";
import { authReducer } from "store/reducers/auth";
import { presentationDocumentReducer } from "store/reducers/presentation";
import { locAmendmentReducer } from "store/reducers/locAmendment";
import { locPresentationReducer } from "store/reducers/locPresentation";
import { displayReducer } from "store/reducers/display";

const buildRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    locCreateReducer,
    locsReducer,
    locDetailsReducer,
    authReducer,
    presentationDocumentReducer,
    locAmendmentReducer,
    locPresentationReducer,
    displayReducer
  });

export { buildRootReducer };