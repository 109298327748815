/* eslint-disable quotes */
import { makeStyles } from "@material-ui/styles";

export const useEventsLogStyles = makeStyles(theme => ({
  pageContainer: {
    padding: "3rem 4rem",
    overflowY: "auto",
    height: `calc(100vh - ${theme.headerHeight} - 6rem - .5rem)`,
    backgroundColor: "#F2F3F4"
  },
  closeButton: {
    width: "1.9rem",
    height: "1.9rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "lightgray",
      borderRadius: 2
    }
  },
  closeIcon: {
    width: "1.8rem",
    height: "1.8rem"
  },
  titleContainer: {
    display: "flex", 
    justifyContent: "space-between",
    fontSize: 22,
    marginBottom: "1rem"
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30vh"
  }
}));

