import {
  RECEIVE_LOC_DETAILS,
  REQUEST_LOC_DETAILS,
  CLEAR_LOC,
} from "store/constants";

const initialState = {
  loc: {},
  isFetching: false,
};

export const locDetailsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch(type) {
  case REQUEST_LOC_DETAILS:
    return {
      ...state,
      isFetching: true,
    };
  case RECEIVE_LOC_DETAILS:
    return {
      ...state,
      isFetching: false,
      loc: payload.loc,
    };
  case CLEAR_LOC:
    return {
      ...state,
      loc: {},
    };
  default:
    return state;
  }
};
