import { makeStyles } from "@material-ui/styles";

export const useRequiredDocsStyles = makeStyles({
  root: {},
  title: {
    fontSize: "0.9rem",
    fontWeight: 600
  },
  reqDocuments: {
    "& div": {
      marginTop: ".5rem",
      "& span": {
        color: "gray",
        marginLeft: ".8rem",
        fontSize: "0.9rem",
      },
    },
  },
  documentName: {
    fontSize: "0.9rem"
  },
  conditions: {
    cursor: "pointer"
  },
  disabledConditions: {
    opacity: 0.7
  },
  flagIcon: {
    width: 10, 
    marginRight: 3
  }
});
