/* eslint-disable no-undef */
const DEV_BASE_URL = "https://8m57p6g35l.execute-api.us-east-1.amazonaws.com/dev10/";
const PROD_BASE_URL = "https://c3xbydh9zf.execute-api.us-east-1.amazonaws.com/prod/";
const DEV_DOCUMENT_BUCKET = "contura-loc-documents-dev";
const PROD_DOCUMENT_BUCKET = "contura-loc-documents-prod";

const DEV_COGNITO_APP_CLIENT_ID = "5gbqrggq65rtqjsfc0ptv56g0h";
const DEV_COGNITO_POOL_ID = "us-east-1_QJhhfm28N";
const DEV_IDENTITY_POOL_ID = "us-east-1:7dd1aedd-39eb-4b3f-8ecd-c7ed62da3a39";
const DEV_COGNITO_IDENTITY_PROVIDER_NAME = "cognito-idp.us-east-1.amazonaws.com/us-east-1_QJhhfm28N";

const PROD_COGNITO_APP_CLIENT_ID = "50u2v9pa02aqr9nkqeuo5cp4jb";
const PROD_COGNITO_POOL_ID = "us-east-1_gMQOTPIDa";
const PROD_IDENTITY_POOL_ID = "us-east-1:ffb11b2d-1570-46b7-9c75-07aa7ebb9ef2";
const PROD_COGNITO_IDENTITY_PROVIDER_NAME = "cognito-idp.us-east-1.amazonaws.com/us-east-1_gMQOTPIDa";

const PRODUCTION = "production";

const configObject = {
  baseURL: process.env.REACT_APP_ENVIRONMENT === PRODUCTION ? PROD_BASE_URL : DEV_BASE_URL,
  cognitoAppClientId: process.env.REACT_APP_ENVIRONMENT === PRODUCTION ? PROD_COGNITO_APP_CLIENT_ID : DEV_COGNITO_APP_CLIENT_ID,
  cognitoPoolId: process.env.REACT_APP_ENVIRONMENT === PRODUCTION ? PROD_COGNITO_POOL_ID : DEV_COGNITO_POOL_ID,
  identityPoolId: process.env.REACT_APP_ENVIRONMENT === PRODUCTION ? PROD_IDENTITY_POOL_ID : DEV_IDENTITY_POOL_ID,
  cognitoIdentityProviderName: process.env.REACT_APP_ENVIRONMENT === PRODUCTION ? PROD_COGNITO_IDENTITY_PROVIDER_NAME : DEV_COGNITO_IDENTITY_PROVIDER_NAME,
  documentBucket: process.env.REACT_APP_ENVIRONMENT === PRODUCTION ? PROD_DOCUMENT_BUCKET : DEV_DOCUMENT_BUCKET,
};

export { configObject as config };