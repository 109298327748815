import { makeStyles, withStyles } from "@material-ui/styles";
import { InputBase } from "@material-ui/core";

export const useViewDocStyles = makeStyles({
  root: {
    overflow: "auto",
    minHeight: "100%",
  },
  goBack: {
    display: "flex",
    backgroundColor: "#F2F3F4",
    border: "none",
    marginLeft: "3rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    fontSize: 21,
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrowIconContainer: {
    display: "inline-flex",
    marginTop: "auto",
    marginBottom: "auto",
  },
  arrowIcon: {
    height: 20,
    width: 20,
  },
  docNavigation: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "80%",
    margin: "1rem auto",
    paddingBottom: "1.25rem",
  },
  pagesInput: {
    width: "20px",
    height: "20px",
    padding: "0 5px",
    margin: "0 .5rem",
  },
  navIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabledNavIcon: {
    color: "#C2C5CA",
  },
  docInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 2rem",
    "& div": {
      minHeight: "70px",
    },
    "& div > h4": {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19px",
      color: "#232F3E",
      textTransform: "uppercase",
      marginBottom: "0.75rem",
    },
    "& div > span": {
      fontSize: 16,
    },
  },
  approveButton: {
    width: "120px",
    height: "45px",
    color: "#FFF",
    borderRadius: "7px",
    outline: "none",
    backgroundColor: "#52B1B5",
    margin: "0 auto",
    marginLeft: "2rem",
    marginTop: "3rem",
    fontSize: 15,
    "&:hover": {
      backgroundColor: "#52B1B5",
    },
    "&:focus": {
      outline: "none",
    },
  },
  rejectButton: {
    width: "120px",
    height: "45px",
    color: "#000",
    borderRadius: "7px",
    outline: "none",
    backgroundColor: "#CED1D5",
    margin: "0 auto",
    marginLeft: "2rem",
    marginTop: "3rem",
    fontSize: 15,
    "&:hover": {
      backgroundColor: "#CED1D5",
    },
    "&:focus": {
      outline: "none",
    },
  },
  headerActions: {
    display: "flex",
    justifyContent: "flexStart",
    alignItems: "center",
  },
  formMargin: {
    marginLeft: "15%",
  },
  pdfWrapper: {
    minHeight: "542px",
    maxHeight: "calc(100vh - 404px)",
    overflow: "scroll",
  },
  contentContainer: {
    minHeight: "605px !important",
    borderBottom: "1px solid lightgray",
  },
  buttonContainer: {
    marginTop: "1rem",
  },
});

export const BootstrapInput = withStyles(() => ({
  root: {
    "label + &": {
      marginTop: 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFF",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    minWidth: 135,
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
