import { makeStyles } from "@material-ui/styles";

export const useDropZoneStyles = makeStyles({
  root: {
    width: "90%",
    margin: ".75rem auto",
    height: 225,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "#EAEBED",
    borderRadius: "5px",
    "&:active": {
      outlineColor: "#5AB4B8",
    },
    "&:hover": {
      outlineColor: "#5AB4B8",
    },
  },
  files: {
    display: "inline-block !important",
    textAlign: "center !important",
    color: "#A7A1B3",
    "&:hover": {
      cursor: "default",
    },
  },
  pdfIcon: {
    width: 60,
    height: 75
  },
  textInDropZone: {
    margin: 0,
    marginTop: 5, 
    size: 11,
    fontWeight: 400,
    "&:hover": {
      cursor: "default",
    },
  },
  greenText: {
    color: "#52B1B5",
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
    },
  }
});
