import React from "react";
import { useSelector } from "react-redux";
import OverlayWithLoader from "components/overlayWithLoader";

export default function GlobalOverlayWithLoader() {
  const { overlayDisplay } = useSelector((state) => state.displayReducer);

  return(
    <OverlayWithLoader overlayDisplay={overlayDisplay} />
  );
}
