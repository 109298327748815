import { makeStyles } from "@material-ui/styles";

export const useWorkflowPanelStyles = makeStyles({
  root: {
    backgroundColor: "#EAEBED",
    padding: "3rem 5rem 2rem 5rem",
    // height: "calc(100% - 400px)",
    height: "100%",
    "& h6": {
      color: "#232F3E",
      fontSize: "28px",
      lineHeight: "33px",
      verticalAlign: "top"
    },
    "& span": {
      fontSize: "14px",
      lineHeight: "44px"
    },
    color: "#52B1B5"
  },
  documentBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  completedDocuments: {
    marginTop: "1.25rem",
    "& div": {
      marginBottom: ".25rem"
    },
    "& div:last-of-type": {
      marginBottom: "2rem",
    },
  },
  completedTask: {
    color: "#878D95",
  },
  inProgressDocuments: {
    marginTop: "1.25rem",
    "& div": {
      marginBottom: ".25rem"
    },
    "& div:last-of-type": {
      marginBottom: "2rem"
    },
    color: "#52B1B5"
  },
  approvalStepsGroup: {
    marginTop: "2rem",
  },
  completionSteps: {
    marginTop: "1.25rem",
    paddingBottom: "3rem",
    borderBottom: "1px solid lightgray",
    "& div": {
      marginTop: ".25rem",
    },
  },
  inactiveStep: {
    color: "#232F3E"
  },
  viewDoc: {
    color: "#52B1B5",
    "&:hover": {
      cursor: "pointer",
    },
  },
  participants: {
    marginTop: "2rem",
    fontSize: "16",
    textTransform: "uppercase",
    color: "#232F3E",
    "& > p": {
      fontWeight: "bold",
    },
  },
  ovalInactive: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    width: "30px",
    backgroundColor: "#232F3E",
    opacity: "0.5",
    borderRadius: "50%",
    "& span": {
      margin: "auto",
      color: "white",
      width: "11px",
      fontSize: "16px",
      fontWeight: "900",
      textAlign: "center"
    }
  },
  ovalRegions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    width: "30px",
    backgroundColor: "#FE4F8B",
    borderRadius: "50%",
    "& span": {
      margin: "auto",
      color: "white",
      width: "11px",
      fontSize: "16px",
      fontWeight: "900",
      textAlign: "center"
    }
  },
  ovalContura: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    width: "30px",
    backgroundColor: "#52B1B5",
    borderRadius: "50%",
    "& span": {
      margin: "auto",
      color: "white",
      width: "11px",
      fontSize: "16px",
      fontWeight: "900",
      textAlign: "center"
    },
  },
  uploadedOval: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    width: "30px",
    backgroundColor: "#868D95",
    borderRadius: "50%",
    "& span": {
      margin: "auto",
      color: "white",
      width: "11px",
      fontSize: "16px",
      fontWeight: "900",
      textAlign: "center"
    },
  },
  uploadDocument: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabled: {
    pointerEvents: "none",
    "& *":{
      color: "#878D95"
    }
  },
  check: {
    marginRight: 5,
  },
  participant: {
    display: "flex",
    textTransform: "none",
    "&:first-of-type": {
      marginTop: "35px",
    },
  },
  participantLabel: {
    marginLeft: "20px",
    "& p": {
      marginTop: "5px",
      color: "#BEC2C6",
    },
  },
});
